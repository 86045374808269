/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";
import LeafletMap from './components/Leaflet/LeafletMap';
import RouteInformation from './components/RoutingResults/RouteInformation';
// import FixedPluginCalendar from '../components/FixedPlugin/FixedPluginCalendar'
import { useNavigate, useLocation } from 'react-router-dom';
import './scss/Dashboard.scss';
import DnaLoader from "../components/Loading/DnaLoader";
import { dataInputPlaneacion } from "../api/services/get/Planeacion";
import { dataCoordinates } from "../api/services/get/Coordinates";
import { useExportToExcel } from "../api/services/get/PlaneacionExcel";

function Dashboard(props) {
  const [dataFromBackend, setDataFromBackend] = useState([]);
  const [dataFromBackendInit, setDataFromBackendInit] = useState([]);
  const [planeacionId, setPlaneacionId] = useState(0);
  const [planeaciones, setPlaneaciones] = useState([]);
  const [despachoId, setDespachoId] = useState(null);
  const [vehiculos, setVehiculos] = useState([]);
  const [stateMessage, setStateMessage] = useState(1);
  const [response, setResponse] = useState(null);
  
  const navigate = useNavigate();
  // const { setLoggedIn } = useAuth();
  const prevLocation = useLocation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [uploading, setUploading] = useState(false);
  const [selectedPlacas, setSelectedPlacas] = useState(new Set());
  const [selectedRutaIds, setSelectedRutaIds] = useState(new Set());
  const [peajes, setPeajes] = useState([]);
  const [routeReordered, setRouteReordered] = useState(false);
  const delta = 0.1; // Ajusta según la extensión deseada
  const initCoordinatesBounds = [4.662112, -74.117308];
  const initialBounds = [
    [initCoordinatesBounds[0] - delta, initCoordinatesBounds[1] - delta],
    [initCoordinatesBounds[0] + delta, initCoordinatesBounds[1] + delta]
  ];
  const [bounds, setBounds] = useState(initialBounds);
  const { handleExport } = useExportToExcel();
  
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    dataInputPlaneacion(newDate);
  };

  const findPlaneacion = (planeaciones) => {
    if (!planeaciones || planeaciones.length === 0) {
      return null; // Manejar el caso donde no hay planeaciones
    }

    // Buscar el planeacion con activo igual a 1
    const activePlaneacion = planeaciones.find(planeacion => planeacion.activo === 1);

    if (activePlaneacion) {
      return activePlaneacion;
    }

    // Si no hay ningún planeacion con activo igual a 1, buscar el planeacion con el valor máximo de planeacion_id
    const maxPlaneacion = planeaciones.reduce((max, planeacion) =>
            planeacion.planeacion_id > (max.planeacion_id || 0) ? planeacion : max,
        {}
    );

    return maxPlaneacion;
  };

  useEffect(() => {
    const getDataInput = async () => {
      const { despachoIdActual = 0 } = prevLocation.state || {};
      setUploading(true);
      const dataResponse = await dataInputPlaneacion(despachoIdActual, navigate);
      setVehiculos(dataResponse.vehiculos);
      setDespachoId(dataResponse.despacho_id);
      if (dataResponse.planeaciones.length > 0) {
        const planeacionActiva = findPlaneacion(dataResponse.planeaciones);
        const dataFiltered = dataResponse.data.filter(d => d.planeacion_id === planeacionActiva.planeacion_id);
        setResponse(dataResponse);
        setDataFromBackend(dataFiltered);
        setDataFromBackendInit(dataFiltered);
        setPlaneacionId(planeacionActiva.planeacion_id);
        setPlaneaciones(dataResponse.planeaciones);
        setStateMessage(1);
      } else {
        setStateMessage(2);
      }
      setUploading(false);
    };

    const getCoordinates = async () => {
      const dataResponse = await dataCoordinates();
      if (dataResponse.grupo.length > 0) {
        const delta = 0.1; // Ajusta según la extensión deseada
        const newBounds = [
          [dataResponse.grupo[0] - delta, dataResponse.grupo[1] - delta],
          [dataResponse.grupo[0] + delta, dataResponse.grupo[1] + delta]
        ];
        setBounds(newBounds);
        setPeajes(dataResponse.peajes);
      }
    };

    getDataInput();
    getCoordinates();
  }, [prevLocation.state, navigate]);

  // useEffect para reaccionar a cambios en dataFromBackend
  useEffect(() => {
    if (response) {
      const dataFiltered = response.data.filter(d => d.planeacion_id === planeacionId);
      setDataFromBackend(dataFiltered);
      setDataFromBackendInit(dataFiltered);
    }
  }, [planeacionId]);

  return (
      <>
        {/* <div>
          <FixedPluginCalendar onDateChange={handleDateChange} selectedDate={selectedDate} />
        </div> */}
        <div className="content-leafletmap">
          {uploading && (
              <div className="dna-wrapper-background">
                <DnaLoader visible={true} height="50" width="50" ariaLabel="dna-loading" />
              </div>
          )}
          <LeafletMap
              selectedPlacas={selectedPlacas}
              dataFromBackend={dataFromBackend}
              selectedRutaIds={selectedRutaIds}
              stateMessage={stateMessage}
              peajes={peajes}
              bounds={bounds}
              routeReordered={routeReordered}
              despachoId={despachoId}
              setDataFromBackend={setDataFromBackend}
              dataFromBackendInit={dataFromBackendInit}
              setRouteReordered={setRouteReordered}
              setUploading={setUploading}
          />
        </div>
        <div className="content-left-container">
          <RouteInformation
              dataFromBackend={dataFromBackend}
              setDataFromBackend={setDataFromBackend}
              selectedPlacas={selectedPlacas}
              setSelectedPlacas={setSelectedPlacas}
              selectedRutaIds={selectedRutaIds}
              setSelectedRutaIds={setSelectedRutaIds}
              planeaciones={planeaciones}
              planeacionId={planeacionId}
              setPlaneacionId={setPlaneacionId}
              setUploading={setUploading}
              vehiculos={vehiculos}
              handleExport={handleExport}
              setRouteReordered={setRouteReordered}
          />
        </div>
      </>
  );
}

export default Dashboard;
