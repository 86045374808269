/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import LeafletMapGeocoding from './components/Leaflet/LeafletMapGeocoding';
import GeocodingInformation from './components/GeocodingResults/GeocodingInformation';
import { useNavigate, useLocation } from 'react-router-dom';
import { dataInputRuteo } from "../api/services/get/Ruteo";
import { useEffect, useState } from "react";
import './scss/Dashboard.scss';
import DnaLoader from "../components/Loading/DnaLoader";
import GeocodingRequest from "./components/Modals/GeocodingRequest";
import {getVehiculos} from "../api/services/get/Vehiculos";
import { dataCoordinates } from "../api/services/get/Coordinates";

const VerifyGeocoding = () => {

  const [direccionesCliente, setDireccionesCliente] = useState([]);
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [selectedAccuracys, setSelectedAccuracys] = useState(new Set());
  const [selectedPuntosIds, setSelectedPuntosIds] = useState(new Set());
  const [showPuntosNuevos, setShowPuntosNuevos] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [markerInitialPositions, setMarkerInitialPositions] = useState({});
  const [markerPositions, setMarkerPositions] = useState({});
  const [filtroNombre, setFiltroNombre] = useState('');
  const prevLocation = useLocation();
  const [despachoId, setDespachoId] = useState(0);
  const [responseBackend, setResponseBackend] = useState(false);
  const [puntosGeograficosToUpdate, setPuntosGeograficosToUpdate] = useState([]);
  const delta = 0.1; // Ajusta según la extensión deseada
  const initCoordinatesBounds = [4.662112, -74.117308];
  const initialBounds = [
    [initCoordinatesBounds[0] - delta, initCoordinatesBounds[1] - delta],
    [initCoordinatesBounds[0] + delta, initCoordinatesBounds[1] + delta]
  ];
  const [bounds, setBounds] = useState(initialBounds);
  const [vehiculos, setVehiculos] = useState(new Set());
  const [modalVehiclesOpened, setModalVehiclesOpened] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState(new Set());

  useEffect(() => {
    // Puedes realizar operaciones adicionales cuando dataFromBackend cambie
    // En este ejemplo, estamos logueando la nueva dataFromBackend
    // console.log('dataFromBackend ha cambiado:', dataFromBackend);
  }, [selectedAccuracys]);
  
  useEffect(() => {
    const { despachoIdActual = 0 } = prevLocation.state || {};
    setDespachoId(despachoIdActual);

    const getDataInput = async () => {
      try {
        const dataInputResponse = await dataInputRuteo(despachoIdActual, navigate);
        setDireccionesCliente(dataInputResponse.direccionesCliente);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const getCoordinates = async () => {
      const dataResponse = await dataCoordinates();
      if (dataResponse.grupo.length > 0) {
        const delta = 0.1; // Ajusta según la extensión deseada
        const newBounds = [
          [dataResponse.grupo[0] - delta, dataResponse.grupo[1] - delta],
          [dataResponse.grupo[0] + delta, dataResponse.grupo[1] + delta]
        ];
        setBounds(newBounds);
      };
    }
    getDataInput();
    getCoordinates();

    const getDataVehiculos = async () => {
      const vehiculosData = await getVehiculos(navigate);
      setVehiculos(vehiculosData);
      setResponseBackend(true);

      const activeVehiculos = Array.from(vehiculosData)
          .filter(vehiculo => vehiculo.activo === 1)
          .map(vehiculo => vehiculo.vehiculo_id);

      setSelectedVehicles(new Set(activeVehiculos));
    };
    getDataVehiculos()

  }, [prevLocation.state]);

  return (
    <>
        <div className="content-leafletmap">
          {uploading && (
          <div className="dna-wrapper-background">
            <DnaLoader visible={true} height="50" width="50" ariaLabel="dna-loading" />
          </div>
          )}
          <LeafletMapGeocoding
              direccionesCliente={direccionesCliente}
              setSelectedAccuracys={setSelectedAccuracys}
              selectedAccuracys={selectedAccuracys}
              selectedPuntosIds={selectedPuntosIds}
              setSelectedPuntosIds={setSelectedPuntosIds}
              showButtons={showButtons}
              setShowButtons={setShowButtons}
              markerInitialPositions={markerInitialPositions}
              setMarkerInitialPositions={setMarkerInitialPositions}
              markerPositions={markerPositions}
              setMarkerPositions={setMarkerPositions}
              showPuntosNuevos={showPuntosNuevos}
              setUploading={setUploading}
              filtroNombre={filtroNombre}
              despachoId={despachoId}
              setModalVehiclesOpened={setModalVehiclesOpened}
              bounds={bounds}
              puntosGeograficosToUpdate={puntosGeograficosToUpdate}
              setPuntosGeograficosToUpdate={setPuntosGeograficosToUpdate}
              responseBackend={responseBackend}
          />
        </div>
        <div className="content-left-container">
          <GeocodingInformation
              direccionesCliente={direccionesCliente}
              setSelectedAccuracys={setSelectedAccuracys}
              selectedAccuracys={selectedAccuracys}
              selectedPuntosIds={selectedPuntosIds}
              setSelectedPuntosIds={setSelectedPuntosIds}
              showButtons={showButtons}
              setShowButtons={setShowButtons}
              markerInitialPositions={markerInitialPositions}
              setMarkerInitialPositions={setMarkerInitialPositions}
              markerPositions={markerPositions}
              setMarkerPositions={setMarkerPositions}
              showPuntosNuevos={showPuntosNuevos}
              setShowPuntosNuevos={setShowPuntosNuevos}
              filtroNombre={filtroNombre}
              setFiltroNombre={setFiltroNombre}
          />
        <GeocodingRequest
            modalVehiclesOpened={modalVehiclesOpened}
            setModalVehiclesOpened={setModalVehiclesOpened}
            dataVehiculos={vehiculos}
            selectedVehicles={selectedVehicles}
            setSelectedVehicles={setSelectedVehicles}
            navigate={navigate}
            setUploading={setUploading}
            despachoId={despachoId}
            puntosGeograficosToUpdate={puntosGeograficosToUpdate}
        />
        </div>
    </>
  );
}

export default VerifyGeocoding;
