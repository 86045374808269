/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {useState, useEffect} from "react";
import LeafletMapCustomers from './components/Leaflet/LeafletMapCustomers';
import CustomersInformation from './components/CustomersResults/CustomersInformation';
import { useNavigate } from 'react-router-dom';
import './scss/Dashboard.scss';
import getDecryptedAccessToken from '../auth/AccessToken.js';
import DnaLoader from "../components/Loading/DnaLoader";
import { dataInputCustomers } from "../api/services/get/Customers";
import { dataCoordinates } from "../api/services/get/Coordinates";

const Customers = () => {

  const [direccionesCliente, setDireccionesCliente] = useState([]);
  const [clientes, setClientes] = useState([]);
  const accessToken = getDecryptedAccessToken();
  const navigate = useNavigate();
  // const { setLoggedIn } = useAuth();
  const [selectedCustomers, setSelectedCustomers] = useState(new Set());
  const [uploading, setUploading] = useState(false);
  const [selectedAccuracys, setSelectedAccuracys] = useState(new Set());
  const [selectedPuntosIds, setSelectedPuntosIds] = useState(new Set());
  const [showPuntosNuevos, setShowPuntosNuevos] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [markerInitialPositions, setMarkerInitialPositions] = useState({});
  const [markerPositions, setMarkerPositions] = useState({});
  const [numPages, setNumPages] = useState(1);
  const [actualPage, setActualPage] = useState(0);
  const [filtroNombre, setFiltroNombre] = useState('');
  const delta = 0.1; // Ajusta según la extensión deseada
  const initCoordinatesBounds = [4.662112, -74.117308];
  const initialBounds = [
    [initCoordinatesBounds[0] - delta, initCoordinatesBounds[1] - delta],
    [initCoordinatesBounds[0] + delta, initCoordinatesBounds[1] + delta]
  ];
  const [bounds, setBounds] = useState(initialBounds);

  useEffect(() => {
    // Puedes realizar operaciones adicionales cuando dataFromBackend cambie
    // En este ejemplo, estamos logueando la nueva dataFromBackend
    // console.log('dataFromBackend ha cambiado:', dataFromBackend);
  }, [selectedCustomers, filtroNombre]);
  
  useEffect(() => {
    const getDataInput = async () => {
      const dataInputResponse = await dataInputCustomers(navigate);
      setDireccionesCliente(dataInputResponse.direccionesCliente);
      setClientes(dataInputResponse.clientes);
      if (dataInputResponse.clientes.length > 0) {
        setNumPages(Math.ceil(dataInputResponse.clientes.length / 100));
      } else {
        setNumPages(1);
      };
    };
    const getCoordinates = async () => {
      const dataResponse = await dataCoordinates();
      if (dataResponse.grupo.length > 0) {
        const delta = 0.1; // Ajusta según la extensión deseada
        const newBounds = [
          [dataResponse.grupo[0] - delta, dataResponse.grupo[1] - delta],
          [dataResponse.grupo[0] + delta, dataResponse.grupo[1] + delta]
        ];
        setBounds(newBounds);
      };
    }
    getDataInput();
    getCoordinates();
    
  }, []);
  
  return (
    <>
        <div className="content-leafletmap">
          {uploading && (
          <div className="dna-wrapper-background">
            <DnaLoader visible={true} height="50" width="50" ariaLabel="dna-loading" />
          </div>
          )}
          <LeafletMapCustomers
              clientes={clientes}
              selectedCustomers={selectedCustomers}
              setSelectedCustomers={setSelectedCustomers}
              direccionesCliente={direccionesCliente}
              setSelectedAccuracys={setSelectedAccuracys}
              selectedAccuracys={selectedAccuracys}
              selectedPuntosIds={selectedPuntosIds}
              setSelectedPuntosIds={setSelectedPuntosIds}
              showButtons={showButtons}
              setShowButtons={setShowButtons}
              markerInitialPositions={markerInitialPositions}
              setMarkerInitialPositions={setMarkerInitialPositions}
              markerPositions={markerPositions}
              setMarkerPositions={setMarkerPositions}
              showPuntosNuevos={showPuntosNuevos}
              setShowPuntosNuevos={setShowPuntosNuevos}
              uploading={uploading}
              setUploading={setUploading}
              numPages={numPages}
              actualPage={actualPage}
              setActualPage={setActualPage}
              filtroNombre={filtroNombre}
              bounds={bounds}
          />
        </div>
        <div className="content-left-container">
          <CustomersInformation
              clientes={clientes}
              selectedCustomers={selectedCustomers}
              setSelectedCustomers={setSelectedCustomers}
              direccionesCliente={direccionesCliente}
              setSelectedAccuracys={setSelectedAccuracys}
              selectedAccuracys={selectedAccuracys}
              selectedPuntosIds={selectedPuntosIds}
              setSelectedPuntosIds={setSelectedPuntosIds}
              showButtons={showButtons}
              setShowButtons={setShowButtons}
              markerInitialPositions={markerInitialPositions}
              setMarkerInitialPositions={setMarkerInitialPositions}
              markerPositions={markerPositions}
              setMarkerPositions={setMarkerPositions}
              showPuntosNuevos={showPuntosNuevos}
              setShowPuntosNuevos={setShowPuntosNuevos}
              uploading={uploading}
              setUploading={setUploading}
              numPages={numPages}
              actualPage={actualPage}
              setActualPage={setActualPage}
              filtroNombre={filtroNombre}
              setFiltroNombre={setFiltroNombre}
          />
        </div>
    </>
  );
}

export default Customers;
