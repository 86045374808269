import axios from "axios";
import getDecryptedAccessToken from "../../../auth/AccessToken";
import config from "../../../config";

export const dataInputRuteo = async ( despachoIdActual, navigate ) => {
    const accessToken = getDecryptedAccessToken();

    try {
        const apiUrl = `${config.apiBaseUrl}/ConsultarInputRuteo/?despacho_id=${despachoIdActual}`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get(apiUrl, { headers });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Error en la solicitud:', response.data);
            navigate(`/login`);
        }
    } catch (error) {
        console.error('Error en la respuesta del servidor:', error);
        navigate(`/login`);
    }
};
