import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import AdminLayout from 'layouts/Admin/Admin.js';
import RTLLayout from 'layouts/RTL/RTL.js';
import Login from './auth/Login.js';
import { AuthProvider, useAuth } from './auth/AuthContext';

import 'assets/scss/black-dashboard-react.scss';
import 'assets/demo/demo.css';
import 'assets/css/nucleo-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import ThemeContextWrapper from './components/ThemeWrapper/ThemeWrapper';
import BackgroundColorWrapper from './components/BackgroundColorWrapper/BackgroundColorWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {

  return (
    // <AuthProvider>
      <ThemeContextWrapper>
        <BackgroundColorWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/admin/*" element={<AdminLayout />}/>
              {/* <Route path="/rtl/*" element={<RTLLayout />} /> */}
              <Route path="/login" element={<Login />} />
              <Route
                path="*"
                element={<Navigate to="/admin/start" replace />}
              />
            </Routes>
          </BrowserRouter>
        </BackgroundColorWrapper>
      </ThemeContextWrapper>
    // </AuthProvider>
  );
}

root.render(<App />);
