import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import pejae_icon from '../../../assets/img/peaje.png'
import './map.css';
// import markerIcon from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as MarkerIcon} from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as ArrowsIcon} from '../../../assets/img/arrows-out-cardinal-bold-svgrepo-com.svg';
import {Button} from 'reactstrap';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import axios from 'axios';
import getDecryptedAccessToken from '../../../auth/AccessToken.js';

function createCustomIcon(color, markerSelected) {
  const CustomIcon = () => (
    <div style={{ position: 'relative', width: '45px', height: '51px' }}>
      {markerSelected && (
        <div style={{ position: 'absolute', top: '34%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }}>
          <ArrowsIcon fill={color} stroke='black' height='75' width='75' />
        </div>
      )}
      <MarkerIcon fill={color} stroke='black' height='45' width='45' style={{ position: 'relative', zIndex: '2' }} />
    </div>
  );

  return L.divIcon({
    iconSize: [50, 56],
    iconAnchor: [20, 38],
    popupAnchor: [3, -30],
    html: ReactDOMServer.renderToString(<CustomIcon />),
    className: 'dummy'
  });
}

function Map({ coordinates, isValidCoordinates }) {
  const map = useMap();

  if (isValidCoordinates && coordinates.length >= 2) {
    map.fitBounds(coordinates);
  } else if (isValidCoordinates && coordinates.length > 0) {
    // Si hay menos de dos coordenadas, ajusta el zoom para que el único marcador sea visible
    const [lat, lng] = coordinates[0];
    map.setView([lat, lng], 16);
  }

  return null;
}

function LeafletMapCustomers({ clientes, selectedCustomers, setSelectedCustomers, direccionesCliente, setSelectedAccuracys, selectedAccuracys, setSelectedPuntosIds, selectedPuntosIds, showButtons, setShowButtons, markerInitialPositions, setMarkerInitialPositions, markerPositions, setMarkerPositions, showPuntosNuevos, setShowPuntosNuevos, uploading, setUploading, numPages, actualPage, setActualPage, filtroNombre, bounds }) {
  const [puntosGeograficosToUpdate, setPuntosGeograficosToUpdate] = useState([]);
  const markerRef = useRef();
  const navigate = useNavigate();
  const accessToken = getDecryptedAccessToken();

  let filteredClientes = showPuntosNuevos
  ? clientes.filter(item => item.cliente_nuevo)
  : clientes;

  // console.log(filteredClientes);

  if (filtroNombre) {
    filteredClientes = filteredClientes
      .filter(item =>
        item.nombre.toLowerCase().includes(filtroNombre.toLowerCase()) ||
        item.cliente_id_externo.toLowerCase().includes(filtroNombre.toLowerCase())
      )
      .slice(0, 100);
  } else {
    filteredClientes = filteredClientes.slice(
      actualPage * 100,
      (actualPage + 1) * 100
    );
  };

  const clienteIdsSet = new Set(filteredClientes.map(cliente => cliente.cliente_id));

  let filteredData = selectedPuntosIds.size > 0
    ? direccionesCliente.filter(item => selectedPuntosIds.has(item.punto_id))
    : direccionesCliente;

  filteredData = clienteIdsSet
    ? filteredData.filter(item => clienteIdsSet.has(item.cliente_id))
    : filteredData;

  filteredData = showPuntosNuevos
    ? filteredData.filter(item => item.cliente_nuevo)
    : filteredData;

  filteredData = selectedCustomers.size > 0
    ? filteredData.filter(item => selectedCustomers.has(item.cliente_id))
    : filteredData;

  filteredData.forEach((item) => {
    if (item.accuracy === '3') {
      item.color = 'green';
      item.accuracy_name = 'Ubicación precisa';
    } else if (item.accuracy === '2') {
      item.color = 'yellow';
      item.accuracy_name = 'Ubicación estimada';
    } else if (item.accuracy === 'MANUAL') {
      item.color = 'gray';
      item.accuracy_name= 'Ubicación manual';
    } else {
      item.color = 'red';
      item.accuracy_name = 'Ubicación poco precisa';
    }    
  });
  
  // Obtener todas las coordenadas de filteredData
  const initCoordinates = filteredData.map(item => {
    if (markerPositions[item.punto_id]) {
      return markerPositions[item.punto_id];
    } else {
      return [item.latitud, item.longitud];
    }
  });

  var coordinates = bounds;

  if (initCoordinates.length > 0) {
    coordinates = initCoordinates;
  };

  // Verificar si hay valores NaN o undefined en las coordenadas
  const isValidCoordinates = coordinates.every(coord => coord.every(val => typeof val === 'number' && !isNaN(val)));

  // Estilo CSS para el contenedor del mapa
  const mapContainerStyle = {
    width: '100%', // Ancho deseado en píxeles
    height: 'calc(100vh - 78.3px)', // Altura deseada en píxeles
    borderRadius: '5px',
  };

  const handleMarkerDragStart = (puntoId) => {
    setShowButtons(false); // Oculta los botones al iniciar el arrastre
  };
  
  const handleMarkerDragEnd = (puntoId) => {
    const marker = markerRef.current;
    if (marker != null) {
      const { lat, lng } = marker.getLatLng();
      // Actualiza la posición del marcador correspondiente en markerPositions
      setMarkerPositions(prevMarkerPositions => ({
        ...prevMarkerPositions,
        [puntoId]: [lat, lng]
      }));
    }
    setShowButtons(true); // Muestra los botones al finalizar el arrastre
  };  

  const handleConfirmLocation = (puntoId) => {
    // Lógica para confirmar la ubicación
    setShowButtons(false);
    setSelectedPuntosIds(new Set());
    // Obtener una copia del estado actual de puntosGeograficosToUpdate
    const nuevosPuntos = { ...puntosGeograficosToUpdate };

    // Agregar o actualizar el punto en el objeto puntosGeograficosToUpdate
    nuevosPuntos[puntoId] = {
      punto_id: puntoId,
      latitud: markerPositions[puntoId][0],
      longitud: markerPositions[puntoId][1]
    };

    // Actualizar el estado puntosGeograficosToUpdate con los nuevos puntos
    setPuntosGeograficosToUpdate(nuevosPuntos);
  };

  const handleCancelLocation = (puntoId) => {
    // Lógica para cancelar la ubicación
    setShowButtons(false);
    const newSelectedPuntosIds = new Set();
    setSelectedPuntosIds(newSelectedPuntosIds);
    setMarkerPositions(prevMarkerPositions => ({
      ...prevMarkerPositions,
      [puntoId]: markerInitialPositions[puntoId]
    }));
    const nuevosPuntos = { ...puntosGeograficosToUpdate };

    // Verificar si el punto existe antes de eliminarlo
    if (nuevosPuntos.hasOwnProperty(puntoId)) {
      // Eliminar el punto del objeto nuevosPuntos
      delete nuevosPuntos[puntoId];
    }
    
    // Actualizar el estado puntosGeograficosToUpdate con los nuevos puntos
    setPuntosGeograficosToUpdate(nuevosPuntos);
  };

  const handlePuntoIdClick = (puntoId, clienteId) => {
    let selectedPuntosAux = new Set();
    setShowButtons(false);
    if (selectedPuntosIds.has(puntoId)) {
      setSelectedPuntosIds(selectedPuntosAux);
    } else {
      selectedPuntosAux.add(puntoId)
      setSelectedPuntosIds(selectedPuntosAux);
    };
    setSelectedCustomers(() => {
      const newSelectedCustomers = new Set(selectedCustomers);
      if (newSelectedCustomers.has(clienteId)) {
        newSelectedCustomers.delete(clienteId);
      } else {
        newSelectedCustomers.add(clienteId);
      }
      return newSelectedCustomers;
    });
  };

  const consultarEstadoRuta = (task_id) => {
    const apiUrl = `${config.apiBaseUrl}/ConsultarEstadoRutear/?task_id=${task_id}`;  // Agrega el ID de la tarea como parámetro de consulta en la URL
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return Promise.reject('No se encontró un token de acceso en localStorage');
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    return axios.get(apiUrl, { headers })
      .then(response => {
        if (response.status === 200) {
          return response.data.status;
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
          throw new Error(response.data.message || 'Error en la respuesta del servidor');
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
        throw error; // Propaga el error para que el cliente pueda manejarlo
      });
  }  

  const rutear = () => {
    const apiUrl = `${config.apiBaseUrl}/RutearDjangoQ/`;
    const postData = {};
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          const task_id = response.data.task_id;
          let timeoutCounter = 0; // Contador de tiempo transcurrido
          // Llama a consultarEstadoRuta cada 20 segundos
          const intervalId = setInterval(() => {
            timeoutCounter += 20000; // Incrementa el contador cada 20 segundos
            if (timeoutCounter >= 1800000) { // Si han pasado 15 minutos (900000 ms)
              clearInterval(intervalId); // Detiene la ejecución repetida
              console.error('La tarea ha excedido el límite de tiempo');
              // Aquí podrías mostrar un mensaje al usuario indicando que la tarea ha excedido el límite de tiempo
              return;
            }
            consultarEstadoRuta(task_id)
              .then(status => {
                if (status === 'success') {
                  clearInterval(intervalId); // Detiene la ejecución repetida una vez que el estado es "success"
                  navigate(`/admin/dashboard`);
                } else if (status === 'error') {
                  clearInterval(intervalId); // Detiene la ejecución repetida si el estado es "error"
                  console.error('La tarea ha finalizado con un error');
                  // Aquí podrías mostrar un mensaje al usuario indicando que la tarea ha finalizado con un error
                }
              })
              .catch(error => {
                console.error('Error al consultar el estado de la tarea:', error);
                clearInterval(intervalId); // Detiene la ejecución repetida en caso de error
              });
          }, 20000); // Llama a consultarEstadoRuta cada 20 segundos
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  }  

  const handleConfirmEditPoints = () => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/ActualizarPuntosGeograficos/`;
    const postData = {
      puntosGeograficosToUpdate: puntosGeograficosToUpdate,
    };

    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }

    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  const handleRestore = () => {
    setMarkerPositions(markerInitialPositions);
    setSelectedPuntosIds(new Set());
    setPuntosGeograficosToUpdate({});
  };

  useEffect(() => {
    // Al montar el componente, almacenar las posiciones iniciales de los marcadores
    const initialMarkerPositions = {};
    Object.entries(filteredData).forEach(([key, item]) => {
      initialMarkerPositions[item.punto_id] = [item.latitud, item.longitud];
      if (puntosGeograficosToUpdate[item.punto_id]) {
      }
    });
    setMarkerPositions(initialMarkerPositions);
    setMarkerInitialPositions(initialMarkerPositions);
  }, []); // Solo se ejecuta una vez al montar el componente

  return (
    <div>
      <MapContainer
        bounds={bounds}
        zoom={12}
        scrollWheelZoom={true}
        style={mapContainerStyle}
      >
        <Map coordinates={coordinates} isValidCoordinates={isValidCoordinates} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
        />
        {filteredData.map(item => (
            <div key={item.direccioncliente_id}> {/* Key debe estar en el contenedor del Marker y los botones */}
              <Marker
                position={markerPositions[item.punto_id] || [item.latitud, item.longitud]}
                icon={createCustomIcon(item.color, selectedPuntosIds.has(item.punto_id))}
                draggable={selectedPuntosIds.has(item.punto_id)} // Hace que el marcador sea draggable
                ref={markerRef} // Asignar referencia al objeto markerRefs
                eventHandlers={{
                  click: () => handlePuntoIdClick(item.punto_id, item.cliente_id),
                  dragend: () => handleMarkerDragEnd(item.punto_id),
                  // dragstart: () => handleMarkerDragStart(puntoId)
                }}
              />
              {showButtons && (
                <div className="map-buttons-container">
                  <Button className="btn-icon" color="success" onClick={() => handleConfirmLocation(item.punto_id)}>
                    <i className="tim-icons icon-check-2"/>
                  </Button>
                  <Button className="btn-icon" color="danger" onClick={() => handleCancelLocation(item.punto_id)}>
                    <i className="tim-icons icon-simple-remove"/>
                  </Button>
                </div>
              )}
            </div>
        ))}
        {!showButtons && (
          <>
            {/* <div className="continue-button-container">
              <Button
                color="info"
                onClick={() => handleContinue()}
                style={{ fontSize: "2rem" }} // Estilos directos en línea
                className="animation-on-hover"
              >
                Rutear
              </Button>
            </div> */}
            {Object.keys(puntosGeograficosToUpdate).length > 0 && ( // Verificar tamaño del objeto
              <div className="restore-confirm-buttons-container">
                <Button color="success" onClick={() => handleConfirmEditPoints()}>Confirmar Cambios</Button>
                <Button color="warning" onClick={() => handleRestore()}>Restablecer Cambios</Button>
              </div> 
            )}
          </>
        )}
      </MapContainer>
    </div>
  );
}

export default LeafletMapCustomers;