/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useRef } from "react";

// reactstrap components
import { Button, Dropdown, DropdownToggle, Badge, Row, UncontrolledAlert } from "reactstrap";
import { ThemeContext, themes } from "contexts/ThemeContext";
import { backgroundColors } from "contexts/BackgroundColorContext";

import config from '../../config';
import { useAuth } from '../../auth/AuthContext.js';
import { useNavigate, useLocation } from 'react-router-dom';
import getDecryptedAccessToken from '../../auth/AccessToken.js';
import DnaLoader from "../Loading/DnaLoader";

function FixedPlugin(props) {
  const [dropDownIsOpen, setdropDownIsOpen] = React.useState(false);
  const handleClick = () => {
    setdropDownIsOpen(!dropDownIsOpen);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = useRef();
  const apiUrl = `${config.apiBaseUrl}/CarguePedidosMasivo/`;
  const navigate = useNavigate();
  const { setLoggedIn } = useAuth();
  const prevLocation = useLocation();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('requiere_limpieza', true);

      // Obtén el token de acceso de localStorage
      const accessToken = getDecryptedAccessToken();

      if (accessToken) {
        // Agrega el token de acceso como encabezado "Authorization" con el formato "Bearer <token>"
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        fetch(apiUrl, {
          method: 'POST',
          body: formData,
          headers: headers, // Agrega los encabezados a la solicitud
        })
        .then((response) => {
          if (response.ok) {
            // La carga fue exitosa, puedes manejar la respuesta aquí
            setUploadSuccess(true);
            return response.json();
          } else if (response.status === 401) {
            // Si la respuesta es 401 (No autorizado), el token probablemente ha expirado
            // Ejecuta la función logoutUser o toma medidas para manejar el error de autenticación
            console.error('Token de acceso inválido o expirado');
            setLoggedIn(false);
            navigate(`/login?redirectTo=${prevLocation}`);
            throw new Error('Token de acceso inválido o expirado');
          } else {
            // Manejar otros errores de respuesta
            console.error('Error en la solicitud:', response.statusText);
          }
        })
        .then((data) => {
          // Manejar la respuesta JSON del servidor si es necesario
          console.log('Respuesta JSON:', data);
        })
        .catch((error) => {
          // Manejar otros errores, incluyendo los relacionados con la red
          console.error('Error al subir el archivo:', error);
          setLoggedIn(false);
          navigate(`/login?redirectTo=${prevLocation}`);
        })
        .finally(() => {
          // Actualizar el estado después de cargar (por ejemplo, para ocultar el loader)
          setUploading(false);
          setSelectedFile(null);
        });
      }
    }
  };

  return (
    <div className='content'>
        {uploading && (
        <div className="dna-wrapper-background">
          <DnaLoader visible={true} height="50" width="50" ariaLabel="dna-loading" />
        </div>
        )}
        {uploadSuccess && (
            <UncontrolledAlert color="success">¡Se cargo exitosamente el archivo!</UncontrolledAlert>
        )}
      <div className="fixed-plugin">
        <Dropdown isOpen={dropDownIsOpen} toggle={handleClick}>
          <DropdownToggle tag="div">
            <i className="fa fa-plus fa-2xl" />
          </DropdownToggle>
          <ul className="dropdown-menu show">
            <li className="header-title">AGREGAR VISITAS</li>
            <li className="button-container">
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!uploading && (
                  <div>
                    <input
                      type="file"
                      accept=".xlsx"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <Button className="btn-rnd btn-icon btn-lg" color="info" onClick={() => fileInputRef.current.click()}>
                      <i class="fa-solid fa-file-import fa-xl"></i>
                    </Button>
                  </div>
                )}
              </Row>
              {selectedFile && !uploading && (
                <div className="social-buttons">
                  <Button color="success" block className="btn-round animation-on-hover" onClick={handleUpload}>
                    Importar
                  </Button>
                </div>
              )}
            </li>
          </ul>
        </Dropdown>
      </div>
    </div>
  );
}

export default FixedPlugin;
