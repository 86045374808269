import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './map.css';
// import markerIcon from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as MarkerIcon} from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as ArrowsIcon} from '../../../assets/img/arrows-out-cardinal-bold-svgrepo-com.svg';
import {Button} from 'reactstrap';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import {postRuteo} from "../../../api/services/post/Ruteo";
import config from '../../../config';
import axios from 'axios';
import getDecryptedAccessToken from '../../../auth/AccessToken.js';

function createCustomIcon(color, markerSelected) {
  const CustomIcon = () => (
    <div style={{ position: 'relative', width: '45px', height: '51px' }}>
      {markerSelected && (
        <div style={{ position: 'absolute', top: '34%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }}>
          <ArrowsIcon fill={color} stroke='black' height='75' width='75' />
        </div>
      )}
      <MarkerIcon fill={color} stroke='black' height='45' width='45' style={{ position: 'relative', zIndex: '2' }} />
    </div>
  );

  return L.divIcon({
    iconSize: [50, 56],
    iconAnchor: [20, 38],
    popupAnchor: [3, -30],
    html: ReactDOMServer.renderToString(<CustomIcon />),
    className: 'dummy'
  });
}

function Map({ coordinates, isValidCoordinates }) {
  const map = useMap();

  if (isValidCoordinates && coordinates.length >= 2) {
    map.fitBounds(coordinates);
  } else if (isValidCoordinates && coordinates.length > 0) {
    // Si hay menos de dos coordenadas, ajusta el zoom para que el único marcador sea visible
    const [lat, lng] = coordinates[0];
    map.setView([lat, lng], 16);
  }

  return null;
}

const LeafletMapGeocoding = ({direccionesCliente, setSelectedAccuracys, selectedAccuracys, setSelectedPuntosIds,
   selectedPuntosIds, showButtons, setShowButtons, markerInitialPositions, setMarkerInitialPositions, markerPositions,
   setMarkerPositions, showPuntosNuevos, setUploading, filtroNombre, despachoId, setModalVehiclesOpened, bounds,
   puntosGeograficosToUpdate, setPuntosGeograficosToUpdate,responseBackend
}) => {
  const markerRef = useRef();
  const navigate = useNavigate();
  const accessToken = getDecryptedAccessToken();
  const handleConfirmEditPoints = () => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/ActualizarPuntosGeograficos/`;
    const postData = {
      puntosGeograficosToUpdate: puntosGeograficosToUpdate,
    };

    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }

    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  let filteredData = selectedAccuracys.size > 0
    ? direccionesCliente.filter(item => selectedAccuracys.has(item.accuracy))
    : direccionesCliente;

  filteredData = filteredData
    .filter(item => item.nombre.toLowerCase().includes(filtroNombre.toLowerCase()))
    .slice(0, 100);

  filteredData = selectedPuntosIds.size > 0
    ? direccionesCliente.filter(item => selectedPuntosIds.has(String(item.punto_id)))
    : filteredData;

  filteredData = showPuntosNuevos
    ? filteredData.filter(item => item.punto_nuevo)
    : filteredData;

  const accuracyGroups = {};
  filteredData.forEach((item) => {
    if (!accuracyGroups[item.accuracy]) {
      accuracyGroups[item.accuracy] = {
        puntos: {},
        color: '',
        name: '',
      };
    }
    if (!accuracyGroups[item.accuracy].puntos[item.punto_id]) {
      accuracyGroups[item.accuracy].puntos[item.punto_id] = {
        direcciones_clientes: [],
        nombres_clientes: [],
        latitud: 0,
        longitud: 0,
        nuevo: true,
      };
    }
    if (item.accuracy === '3') {
      accuracyGroups[item.accuracy].color = 'green';
      accuracyGroups[item.accuracy].name = 'Ubicación precisa';
    } else if (item.accuracy === '2') {
      accuracyGroups[item.accuracy].color = 'yellow';
      accuracyGroups[item.accuracy].name = 'Ubicación estimada';
    } else if (item.accuracy === 'MANUAL') {
      accuracyGroups[item.accuracy].color = 'gray';
      accuracyGroups[item.accuracy].name = 'Ubicación manual';
    } else {
      accuracyGroups[item.accuracy].color = 'red';
      accuracyGroups[item.accuracy].name = 'Ubicación poco precisa';
    }
    accuracyGroups[item.accuracy].puntos[item.punto_id].direcciones_clientes.push(item.direccion);
    accuracyGroups[item.accuracy].puntos[item.punto_id].nombres_clientes.push(item.nombre);
    accuracyGroups[item.accuracy].puntos[item.punto_id].latitud = item.latitud;
    accuracyGroups[item.accuracy].puntos[item.punto_id].longitud = item.longitud;
    accuracyGroups[item.accuracy].puntos[item.punto_id].nuevo = item.punto_nuevo;
  });
  
  // Obtener todas las coordenadas de filteredData
  const initCoordinates = filteredData.map(item => {
    if (markerPositions[item.punto_id]) {
      return markerPositions[item.punto_id];
    } else {
      return [item.latitud, item.longitud];
    }
  });

  var coordinates = bounds;

  if (initCoordinates.length > 0) {
    coordinates = initCoordinates;
  };

  // Verificar si hay valores NaN o undefined en las coordenadas
  const isValidCoordinates = coordinates.every(coord => coord.every(val => typeof val === 'number' && !isNaN(val)));

  // Estilo CSS para el contenedor del mapa
  const mapContainerStyle = {
    width: '100%', // Ancho deseado en píxeles
    height: 'calc(100vh - 78.3px)', // Altura deseada en píxeles
    borderRadius: '5px',
  };
  
  const handleMarkerDragEnd = (puntoId) => {
    const marker = markerRef.current;
    if (marker != null) {
      const { lat, lng } = marker.getLatLng();
      // Actualiza la posición del marcador correspondiente en markerPositions
      setMarkerPositions(prevMarkerPositions => ({
        ...prevMarkerPositions,
        [puntoId]: [lat, lng]
      }));
    }
    setShowButtons(true); // Muestra los botones al finalizar el arrastre
  };  

  const handleConfirmLocation = (puntoId) => {
    // Lógica para confirmar la ubicación
    setShowButtons(false);
    setSelectedPuntosIds(new Set());
    // Obtener una copia del estado actual de puntosGeograficosToUpdate
    const nuevosPuntos = { ...puntosGeograficosToUpdate };

    // Agregar o actualizar el punto en el objeto puntosGeograficosToUpdate
    nuevosPuntos[puntoId] = {
      punto_id: puntoId,
      latitud: markerPositions[puntoId][0],
      longitud: markerPositions[puntoId][1]
    };

    // Actualizar el estado puntosGeograficosToUpdate con los nuevos puntos
    setPuntosGeograficosToUpdate(nuevosPuntos);
  };

  const handleCancelLocation = (puntoId) => {
    // Lógica para cancelar la ubicación
    setShowButtons(false);
    const newSelectedPuntosIds = new Set();
    setSelectedPuntosIds(newSelectedPuntosIds);
    setMarkerPositions(prevMarkerPositions => ({
      ...prevMarkerPositions,
      [puntoId]: markerInitialPositions[puntoId]
    }));
    const nuevosPuntos = { ...puntosGeograficosToUpdate };

    // Verificar si el punto existe antes de eliminarlo
    if (nuevosPuntos.hasOwnProperty(puntoId)) {
      // Eliminar el punto del objeto nuevosPuntos
      delete nuevosPuntos[puntoId];
    }
    
    // Actualizar el estado puntosGeograficosToUpdate con los nuevos puntos
    setPuntosGeograficosToUpdate(nuevosPuntos);
  };

  const handlePuntoIdClick = (puntoId) => {
    const puntoFiltered = filteredData.find(item => item.punto_id === parseInt(puntoId));
    let selectedPuntosAux = new Set();
    setShowButtons(false);
    if (selectedPuntosIds.has(puntoId)) {
      setSelectedPuntosIds(selectedPuntosAux);
    } else {
      selectedPuntosAux.add(puntoId)
      setSelectedPuntosIds(selectedPuntosAux);
    }
    if (selectedAccuracys.has(puntoFiltered.accuracy) && selectedPuntosAux.has(puntoId)) {
      setSelectedAccuracys(new Set([puntoFiltered.accuracy]));
    } else if ( selectedPuntosAux.has(puntoId)) {
      setSelectedAccuracys(new Set([puntoFiltered.accuracy]));
    } else {
      setSelectedAccuracys(new Set([puntoFiltered.accuracy]));
    }    
  };

  const handleContinue = () => {
    setModalVehiclesOpened(true);
    /* handlePostRuteo()
        .then(r => console.log("Ruteando"))*/
  };

  const handlePostRuteo = async () => {
    setUploading(true);
    await postRuteo(puntosGeograficosToUpdate, navigate);
  }

  const handleRestore = () => {
    setMarkerPositions(markerInitialPositions);
    setSelectedPuntosIds(new Set());
    setPuntosGeograficosToUpdate({});
  };

  useEffect(() => {
    // Al montar el componente, almacenar las posiciones iniciales de los marcadores
    const initialMarkerPositions = {};
    Object.entries(accuracyGroups).forEach(([accuracy, accuracyGroup]) => {
      Object.entries(accuracyGroup.puntos).forEach(([puntoId, puntoGroup]) => {
        initialMarkerPositions[puntoId] = [puntoGroup.latitud, puntoGroup.longitud];
        if (puntosGeograficosToUpdate[puntoId]) {
          console.log(puntoId);
        }
      });
    });
    setMarkerPositions(initialMarkerPositions);
    setMarkerInitialPositions(initialMarkerPositions);
  }, []); // Solo se ejecuta una vez al montar el componente

  return (
    <div>
      <MapContainer
        bounds={bounds}
        zoom={12}
        scrollWheelZoom={true}
        style={mapContainerStyle}
      >
        <Map coordinates={coordinates} isValidCoordinates={isValidCoordinates} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
        />
        {Object.entries(accuracyGroups).map(([accuracy, accuracyGroup]) => (
          Object.entries(accuracyGroup.puntos).map(([puntoId, puntoGroup]) => (
            <div key={puntoId}> {/* Key debe estar en el contenedor del Marker y los botones */}
              <Marker
                position={markerPositions[puntoId] || [puntoGroup.latitud, puntoGroup.longitud]}
                icon={createCustomIcon(accuracyGroup.color, selectedPuntosIds.has(puntoId))}
                draggable={selectedPuntosIds.has(puntoId)} // Hace que el marcador sea draggable
                ref={markerRef} // Asignar referencia al objeto markerRefs
                eventHandlers={{
                  click: () => handlePuntoIdClick(puntoId),
                  dragend: () => handleMarkerDragEnd(puntoId),
                  // dragstart: () => handleMarkerDragStart(puntoId)
                }}
              />
              {showButtons && (
                <div className="map-buttons-container">
                  <Button className="btn-icon" color="success" onClick={() => handleConfirmLocation(puntoId)}>
                    <i className="tim-icons icon-check-2"/>
                  </Button>
                  <Button className="btn-icon" color="danger" onClick={() => handleCancelLocation(puntoId)}>
                    <i className="tim-icons icon-simple-remove"/>
                  </Button>
                </div>
              )}
            </div>
          ))
        ))}
        {!showButtons && (
          <>
            {responseBackend && (
              <div className="continue-button-container">
                <Button
                  color="info"
                  onClick={() => handleContinue()}
                  style={{ fontSize: "2rem" }} // Estilos directos en línea
                  className="animation-on-hover"
                >
                  <i class="fa-solid fa-arrow-right"></i>
                </Button>
              </div>
            )}
            {Object.keys(puntosGeograficosToUpdate).length > 0 && ( // Verificar tamaño del objeto
              <div className="restore-confirm-buttons-container">
                <Button color="success" onClick={() => handleConfirmEditPoints()}>Confirmar Cambios</Button>
                <Button color="warning" onClick={() => handleRestore()}>Restablecer Cambios</Button>
              </div> 
            )}
          </>
        )}
      </MapContainer>
    </div>
  );
}

export default LeafletMapGeocoding;