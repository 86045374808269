// DnaLoader.js
import React from 'react';
import PropTypes from 'prop-types';
import './DnaLoader.css'; // Estilo para la animación
import { Dna, ThreeCircles } from 'react-loader-spinner';

const DnaLoader = ({ visible, height, width, ariaLabel, wrapperStyle, wrapperClass }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={`DnaLoader-wrapper ${wrapperClass}`} style={wrapperStyle}>
        {/* <Dna
            visible={true}
            height="30"
            width="30"
            ariaLabel={ariaLabel}
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
        /> */}
        <ThreeCircles
            height="130"
            width="130"
            color="#1d8cf8"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
        />
    </div>
  );
};

DnaLoader.propTypes = {
  visible: PropTypes.bool.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  wrapperStyle: PropTypes.object,
  wrapperClass: PropTypes.string,
};

export default DnaLoader;
