import axios from "axios";
import getDecryptedAccessToken from "../../../auth/AccessToken";
import config from "../../../config";
import { getEstadoRuta } from "../get/EstadoRuta";

const accessToken = getDecryptedAccessToken();

export const postRuteo = async (puntosGeograficos=null, despachoId, navigate, reorder=false, planeacion_data=null, timeGetEstadoRuta=20000) => {
    if (puntosGeograficos) {
        const apiUrl = `${config.apiBaseUrl}/ActualizarPuntosGeograficos/`;
        const postData = {
            puntosGeograficosToUpdate: puntosGeograficos,
        };

        // Verifica si se ha almacenado un token de acceso en localStorage
        if (!accessToken) {
            console.error('No se encontró un token de acceso en localStorage');
            return;
        }

        // Configura los encabezados para incluir el token de acceso como Bearer
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            const response = await axios.post(apiUrl, postData, { headers });
            if (response.status === 200) {
                await rutear(despachoId, navigate, reorder, planeacion_data, timeGetEstadoRuta);
            } else if (response.status === 401) {
                console.error('Token de acceso inválido o expirado');
                navigate(`/login`);
                throw new Error('Token de acceso inválido o expirado');
            } else {
                console.error('Error en la respuesta del servidor:', response.data.message);
                throw new Error(response.data.message);
            }
        } catch (error) {
            navigate(`/login`);
            console.error('Error al realizar la solicitud:', error);
            throw error;
        }
    } else {
        await rutear(despachoId, navigate, reorder, planeacion_data, timeGetEstadoRuta);
    }
};

const rutear = async (despachoId, navigate, reorder, planeacion_data, timeGetEstadoRuta) => {
    const apiUrl = `${config.apiBaseUrl}/RutearDjangoQ/`;
    const postData = {
        despacho_id: despachoId,
        reordenar: reorder,
        planeacion_data: planeacion_data
    };

    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
        console.error('No se encontró un token de acceso en localStorage');
        return;
    }

    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    try {
        const response = await axios.post(apiUrl, postData, { headers });
        if (response.status === 200) {
            const task_id = response.data.task_id;
            let timeoutCounter = 0; // Contador de tiempo transcurrido

            return new Promise((resolve, reject) => {
                // Llama a consultarEstadoRuta cada 20 segundos
                const intervalId = setInterval(async () => {
                    timeoutCounter += timeGetEstadoRuta; // Incrementa el contador cada 20 segundos
                    if (timeoutCounter >= 1800000) { // Si han pasado 15 minutos (900000 ms)
                        clearInterval(intervalId); // Detiene la ejecución repetida
                        console.error('La tarea ha excedido el límite de tiempo');
                        reject(new Error('La tarea ha excedido el límite de tiempo'));
                        return;
                    }

                    try {
                        const status = await getEstadoRuta(task_id, navigate);
                        if (status === 'success') {
                            clearInterval(intervalId); // Detiene la ejecución repetida una vez que el estado es "success"
                            navigate('/admin/dashboard', { state: { despachoIdActual: despachoId } });
                            resolve();
                        } else if (status === 'error') {
                            clearInterval(intervalId); // Detiene la ejecución repetida si el estado es "error"
                            console.error('La tarea ha finalizado con un error');
                            reject(new Error('La tarea ha finalizado con un error'));
                        }
                    } catch (error) {
                        console.error('Error al consultar el estado de la tarea:', error);
                        clearInterval(intervalId); // Detiene la ejecución repetida en caso de error
                        reject(error);
                    }
                }, timeGetEstadoRuta); // Llama a consultarEstadoRuta cada 20 segundos
            });
        } else if (response.status === 401) {
            console.error('Token de acceso inválido o expirado');
            navigate(`/login`);
            throw new Error('Token de acceso inválido o expirado');
        } else {
            console.error('Error en la respuesta del servidor:', response.data.message);
            throw new Error(response.data.message);
        }
    } catch (error) {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
        throw error;
    }
};
