import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import pejae_icon from '../../../assets/img/peaje.png'
import './map.css';
// import markerIcon from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as MarkerIcon} from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as WarehouseIcon} from '../../../assets/img/warehouse-svgrepo-com.svg';
import ReactDOMServer from 'react-dom/server';
import {Button} from 'reactstrap';
import {postRuteo} from "../../../api/services/post/Ruteo";
import { useNavigate } from 'react-router-dom';
// import { mockPeajes } from "../../../api/utils/MockPeajes";

// Función para crear un icono personalizado con el color y número especificados
function createCustomIcon(color, number, tipoNodo, reordenado) {
  const CustomIcon = () => (
    <div style={{ position: 'relative', width: '45px', height: '51px' }}>
      {tipoNodo === 'CLI' ? (
        <>
          <MarkerIcon 
            fill={color} 
            stroke={reordenado ? '#f5365c' : 'black'} 
            strokeWidth={reordenado ? '5px' : '1px'} 
            height='45' 
            width='45' 
          />
          <div 
            style={{ 
              position: 'absolute', 
              top: '35%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)', 
              color: 'black', 
              fontSize: '6px', 
              fontWeight: 'bold' 
            }}
          >
            {number}
          </div>
        </>
      ) : (
        <WarehouseIcon height='35' width='35' />
      )}
    </div>
  );

  return L.divIcon({
    iconSize: [50, 56],
    iconAnchor: [20, 38],
    popupAnchor: [3, -30],
    html: ReactDOMServer.renderToString(<CustomIcon />),
    className: 'dummy'
  });
}

function peajeIcon() {
    return new L.Icon({
      iconUrl: pejae_icon,
      iconSize: [32, 32], // Ajusta el tamaño del icono aquí (ancho, alto)
      iconAnchor: [16, 32], // Ajusta el punto de anclaje si es necesario
      popupAnchor: [-7, -25] // Ajusta el punto de anclaje del popup si es necesario
    });
  }

function Map({ coordinates, isValidCoordinates }) {
    const map = useMap()
    if (isValidCoordinates && coordinates.length >= 2) {
      // setBounds(coordinates);
      map.fitBounds(coordinates);
    }
    return null
  }

function LeafletMap({ selectedPlacas, dataFromBackend, selectedRutaIds, stateMessage, peajes, bounds,
  despachoId, routeReordered, setDataFromBackend, dataFromBackendInit, setRouteReordered, setUploading }) {
  const navigate = useNavigate();
  // Filtra los datos basados en las placas seleccionadas
  let filteredData = selectedPlacas.size > 0
    ? dataFromBackend.filter(item => selectedPlacas.has(String(item.rutasVehiculo_id)))
    : dataFromBackend;

  // Filtra los datos basados en las placas seleccionadas
  filteredData = selectedRutaIds.size > 0
    ? filteredData.filter(item => selectedRutaIds.has(String(item.ruta_id)))
    : filteredData;
    
  // Obtener todas las coordenadas de filteredData
  const initCoordinates = filteredData.map(item => [item.latitud, item.longitud]);
  var coordinates = bounds;

  if (initCoordinates.length > 0) {
    coordinates = initCoordinates;
  }

  // Verificar si hay valores NaN o undefined en las coordenadas
  const isValidCoordinates = coordinates.every(coord => coord.every(val => typeof val === 'number' && !isNaN(val)));

  // Estilo CSS para el contenedor del mapa
  const mapContainerStyle = {
    width: '100%', // Ancho deseado en píxeles
    height: 'calc(100vh - 78.3px)', // Altura deseada en píxeles
    borderRadius: '5px',
  };

  const handleConfirmReorder = () => {
    setRouteReordered(false);
    setUploading(true);
    postRuteo(null, despachoId, navigate, true, dataFromBackend, 1000);
  };

  const handleCancelReorder = () => {
    setDataFromBackend(dataFromBackendInit);
    setRouteReordered(false);
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      {stateMessage === 2 && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '20px',
          borderRadius: '10px',
          textAlign: 'center',
          zIndex: 1000
        }}>
          <h1 style={{ color: '#ff8d72' }}>Aún no se han generado rutas para el despacho seleccionado </h1>
        </div>
      )}
      <MapContainer
        bounds={bounds}
        zoom={12}
        scrollWheelZoom={true}
        style={mapContainerStyle}
      >
        <Map coordinates={coordinates} isValidCoordinates={isValidCoordinates} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
        />
        {filteredData.length > 0 && filteredData.map(item => (
          item.tipoNodo !== 'VEH' && (
            <Marker
              key={item.id}
              position={[item.latitud, item.longitud]}
              icon={createCustomIcon(item.color_leaflet, item.secuencia_ruta + '.' + item.secuencia_punto, item.tipoNodo, item.reordenado)}
            >
              <Popup>
                <div>
                  <p>Secuencia: {item.secuencia_punto}</p>
                  <p>Dirección: {item.direccion}</p>
                  <p>Estimado de Llegada: {item.estimadoLlegada}</p>
                </div>
              </Popup>
            </Marker>
          )
        ))}
        {peajes.map((peaje, index) => (
          <Marker
            key={index}
            position={peaje.coordinates}
            icon={peajeIcon()}
          >
            <Popup>{peaje.name}</Popup>
          </Marker>
        ))}
        {filteredData.length > 0 && filteredData.map(item => (
          <React.Fragment key={item.id}>
            {item.ruta && item.ruta.length > 0 && (
              <Polyline
                positions={JSON.parse(item.ruta)}
                color={item.color_leaflet}
              />
            )}
          </React.Fragment>
        ))}
        {routeReordered && ( // Verificar tamaño del objeto
          <div className="restore-confirm-buttons-container">
            <Button color="success" onClick={() => handleConfirmReorder()}>Confirmar Cambios</Button>
            <Button color="warning" onClick={() => handleCancelReorder()}>Restablecer Cambios</Button>
          </div> 
        )}
      </MapContainer>
    </div>
  );
}

export default LeafletMap;
