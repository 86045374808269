const config = {
  apiBaseUrl: process.env.NODE_ENV === 'production'
    ? 'https://libot.herokuapp.com' // URL del servidor real
    : 'http://127.0.0.1:8000', // URL del servidor local,
  secretKey: process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_SECRET_KEY // Clave de producción desde variable de entorno
    : process.env.REACT_APP_DEVELOPMENT_SECRET_KEY, // Clave de desarrollo desde archivo .env
  initializationVector: process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_IV
    : process.env.REACT_APP_DEVELOPMENT_IV
};

export default config;
