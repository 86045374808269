import axios from "axios";
import getDecryptedAccessToken from "../../../auth/AccessToken";
import config from "../../../config";
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const useExportToExcel = () => {
  const [data, setData] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [currentDespachoId, setCurrentDespachoId] = useState(null);
  const accessToken = getDecryptedAccessToken();

  // Función para obtener los datos de la API de Django Rest Framework
  const fetchData = async (despachoId) => {
    try {
      const apiUrl = `${config.apiBaseUrl}/ConsultarPedidosExcel/?despacho_id=${despachoId}`;

      // Verifica si se ha almacenado un token de acceso en localStorage
      if (!accessToken) {
        console.error('No se encontró un token de acceso en localStorage');
        return;
      }

      // Configura los encabezados para incluir el token de acceso como Bearer
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.get(apiUrl, { headers });
      setData(response.data.pedidos);
      setCurrentDespachoId(despachoId);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Función para generar el archivo XLSX
  const generateXLSX = () => {
    if (data.length === 0) {
      console.error('No hay datos para exportar');
      return;
    }

    // Convierte los datos a una hoja de trabajo (worksheet)
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Crea un nuevo libro de trabajo (workbook) y agrega la hoja de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');

    // Convierte el libro de trabajo a un archivo binario
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Guarda el archivo utilizando file-saver
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `pedidos_despacho_${currentDespachoId}.xlsx`);
  };

  // Efecto para ejecutar generateXLSX cuando los datos están listos
  useEffect(() => {
    if (isExporting && data.length > 0) {
      generateXLSX();
      setIsExporting(false); // Resetear el estado de exportación
    }
  }, [data, isExporting]);

  // Función para manejar la exportación
  const handleExport = async (despachoId) => {
    setIsExporting(true);
    await fetchData(despachoId);
  };

  return { handleExport };
};
