import CryptoJS from 'crypto-js';
import config from '../config';

function getDecryptedAccessToken() {
    // Obtiene el token de acceso encriptado desde el almacenamiento local
    const accessTokenEncrypted = localStorage.getItem('accessToken');
  
    // Verifica si el token encriptado existe
    if (!accessTokenEncrypted) {
      return null; // Puedes manejar esta situación según tus necesidades
    }
  
    // Desencripta el token de acceso utilizando la clave y el IV
    const key = CryptoJS.enc.Hex.parse(config.secretKey);
    const iv = CryptoJS.enc.Hex.parse(config.initializationVector);
    const decryptedAccess = CryptoJS.AES.decrypt(accessTokenEncrypted, key, { iv });
  
    // Convierte el resultado en una cadena UTF-8 y lo retorna
    return decryptedAccess.toString(CryptoJS.enc.Utf8);
  }

export default getDecryptedAccessToken;