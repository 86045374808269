import axios from "axios";
import getDecryptedAccessToken from "../../../auth/AccessToken";
import config from "../../../config";
import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const useExportToExcel = () => {
  const [data, setData] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [currentPlaneacionId, setCurrentPlaneacionId] = useState(null);
  const accessToken = getDecryptedAccessToken();

  // Función para obtener los datos de la API de Django Rest Framework
  const fetchData = async (planeacionId) => {
    try {
      const apiUrl = `${config.apiBaseUrl}/ConsultarPlaneacionExcel/?planeacion_id=${planeacionId}`;

      if (!accessToken) {
        console.error('No se encontró un token de acceso en localStorage');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.get(apiUrl, { headers });
      setData(response.data.planeacion);
      setCurrentPlaneacionId(planeacionId);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Función para generar el archivo XLSX
  const generateXLSX = () => {
    if (data.length === 0) {
      console.error('No hay datos para exportar');
      return;
    }

    // Crea un nuevo libro de trabajo y una hoja de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    // Añade la fila de encabezados con estilo
    const headers = Object.keys(data[0]);
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: '000000' } }; // Texto en negro
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD700' }, // Color de fondo dorado pastel
      };
    });

    // Añade las filas de datos y aplica estilos basados en rutaId
    data.forEach((row) => {
      const newRow = worksheet.addRow(Object.values(row));
      const color = getColorForRouteId(row.rutaId);
      newRow.eachCell((cell) => {
        cell.font = { color: { argb: '000000' } }; // Texto en negro
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color },
        };
      });
    });

    // Genera el archivo y lo guarda utilizando file-saver
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `planeacion_${currentPlaneacionId}.xlsx`);
    });
  };

  // Función para obtener colores basados en rutaId
  const getColorForRouteId = (rutaId) => {
    const colors = ["FFDDC1", "D1E8E2", "FFABAB", "FFC3A0", "FF677D"]; // Colores pastel
    return colors[rutaId % colors.length];
  };

  useEffect(() => {
    if (isExporting && data.length > 0) {
      generateXLSX();
      setIsExporting(false);
    }
  }, [data, isExporting]);

  const handleExport = async (planeacionId) => {
    setIsExporting(true);
    await fetchData(planeacionId);
  };

  return { handleExport };
};
