// En AuthAxios.js
import getDecryptedAccessToken from './AccessToken.js';
import axios from 'axios';
import config from '../config';

export const userAuthenticated = async () => {
    try {
        const apiUrl = `${config.apiBaseUrl}/UsuarioAutenticado/`;
        const accessToken = getDecryptedAccessToken();
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get(apiUrl, { headers });

        if (response.status === 200) {
            return true;
      } else {
            console.error('Error en la solicitud:', response.data);
            return false;
      }
    } catch (error) {
        console.error('Error en la respuesta del servidor:', error);
        return false;
    }
};