import React, { useState } from 'react';
import moment from 'moment';
import { Collapse, Card, CardBody, CardHeader, Label, Input, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './GeocodingInformation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faUser, faClock, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const GeocodingInformation = ({ direccionesCliente, setSelectedAccuracys, selectedAccuracys, setSelectedPuntosIds, selectedPuntosIds, showButtons, setShowButtons, markerInitialPositions, setMarkerInitialPositions, markerPositions, setMarkerPositions, showPuntosNuevos, setShowPuntosNuevos, filtroNombre, setFiltroNombre }) => {

  const [focused, setFocused] = useState(false);

  const accuracyGroups = {};
  let filteredData = showPuntosNuevos
    ? direccionesCliente.filter(item => item.punto_nuevo)
    : direccionesCliente;

  filteredData = filteredData
    .filter(item => item.nombre.toLowerCase().includes(filtroNombre.toLowerCase()))
    .slice(0, 100);

  filteredData.forEach((item) => {
    if (!accuracyGroups[item.accuracy]) {
      accuracyGroups[item.accuracy] = {
        puntos: {},
        color: '',
        name: '',
      };
    }
    if (!accuracyGroups[item.accuracy].puntos[item.punto_id]) {
      accuracyGroups[item.accuracy].puntos[item.punto_id] = {
        direcciones_clientes: [],
        nombres_clientes: [],
        direccioncliente_id: [],
        latitud: 0,
        longitud: 0,
        nuevo: true,
      };
    }
    if (item.accuracy === '3') {
      accuracyGroups[item.accuracy].color = 'green';
      accuracyGroups[item.accuracy].name = 'Ubicación precisa';
    } else if (item.accuracy === '2') {
      accuracyGroups[item.accuracy].color = 'yellow';
      accuracyGroups[item.accuracy].name = 'Ubicación estimada';
    } else if (item.accuracy === 'MANUAL') {
      accuracyGroups[item.accuracy].color = 'gray';
      accuracyGroups[item.accuracy].name = 'Ubicación manual';
    } else {
      accuracyGroups[item.accuracy].color = 'red';
      accuracyGroups[item.accuracy].name = 'Ubicación poco precisa';
    }    
    accuracyGroups[item.accuracy].puntos[item.punto_id].direcciones_clientes.push(item.direccion);
    accuracyGroups[item.accuracy].puntos[item.punto_id].nombres_clientes.push(item.nombre);
    accuracyGroups[item.accuracy].puntos[item.punto_id].direccioncliente_id.push(item.direccioncliente_id);
    accuracyGroups[item.accuracy].puntos[item.punto_id].latitud = item.latitud;
    accuracyGroups[item.accuracy].puntos[item.punto_id].longitud = item.longitud;
    accuracyGroups[item.accuracy].puntos[item.punto_id].nuevo = item.punto_nuevo;
  });

  const handleFiltroNombreChange = (event) => {
    setFiltroNombre(event.target.value);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleAccuracyClick = (accuracy) => {
    setShowButtons(false);
    setSelectedAccuracys((prevSelectedAccuracys) => {
      const newSelectedAccuracys = new Set(prevSelectedAccuracys);
      if (newSelectedAccuracys.has(accuracy)) {
        newSelectedAccuracys.delete(accuracy);
        Object.keys(accuracyGroups[accuracy].puntos).forEach(puntoId => {
          setSelectedPuntosIds((prevSelectedPuntosIds) => {
            const newSelectedPuntosIds = new Set(prevSelectedPuntosIds);
            newSelectedPuntosIds.delete(puntoId);
            return newSelectedPuntosIds;
          });
        });
      } else {
        newSelectedAccuracys.add(accuracy);
      }
      return newSelectedAccuracys;
    });
  };

  const handlePuntoIdClick = (puntoId) => {
    setShowButtons(false);
    if (selectedPuntosIds.has(puntoId)) {
      setSelectedPuntosIds(new Set());
    } else {
      setSelectedPuntosIds(new Set([puntoId]));
    }
  };

  const handlePuntosNuevosChange = () => {
    setShowPuntosNuevos(!showPuntosNuevos);
    setSelectedAccuracys(new Set());
    setSelectedPuntosIds(new Set());
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div className="title-container">
          <h2 className="text-center mb-3 text-white" style={{ paddingTop: '1rem', paddingBottom: '5px' }}>PEDIDOS A RUTEAR</h2>
          <div className="divider-line"></div>
        </div>
        <Card className="mb-3" style={{ marginTop: '10px' }}>
          <CardHeader>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <InputGroup className={focused ? "input-group-focus" : ""}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="tim-icons icon-zoom-split"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Buscar por nombre"
                    onChange={handleFiltroNombreChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </InputGroup>
              </div>
              <div>
                <FormGroup check>
                  <Label className="form-check-label" style={{ fontSize: '0.74rem' }}>
                    <Input className="form-check-input" type="checkbox" value="" onClick={handlePuntosNuevosChange} />
                      Direcciones Nuevas
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {Object.entries(accuracyGroups).map(([accuracy, accuracyGroup]) => (
              <Card key={accuracy} className="mb-3" style={{ marginTop: '10px' }}>
                <CardHeader onClick={() => handleAccuracyClick(accuracy)} style={{ cursor: 'pointer', display: 'flex', paddingBottom: '1rem', paddingTop: '1rem' }}>
                  <span>{accuracyGroup.name}</span>
                  <i className={`ml-auto fas ${selectedAccuracys.has(accuracy) ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ fontSize: '1rem' }} />
                </CardHeader>
                <Collapse isOpen={selectedAccuracys.has(accuracy)}>
                  <CardBody style={{ paddingBottom: '1rem', paddingTop: '0.5rem' }}>
                    {Object.entries(accuracyGroup.puntos).map(([puntoId, puntoGroup]) => (
                      Object.entries(puntoGroup.direcciones_clientes).map(([clienteIndex, direccionCliente], index) => (
                        index === 0 && (
                          <Card key={puntoId} className="mb-2" style={{ position: 'relative' }}>
                            {puntoGroup.direcciones_clientes.length > 1 && (
                              <div style={{ 
                                position: 'absolute', 
                                top: '-4px', 
                                left: '-4px', 
                                background: '#1d8cf8', 
                                borderRadius: '50%', 
                                width: '24px', 
                                height: '24px', 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                color: 'white', 
                                fontSize: '0.8rem' 
                              }}>
                                {puntoGroup.direcciones_clientes.length}
                              </div>
                            )}
                            <CardHeader onClick={() => handlePuntoIdClick(puntoId)} style={{ cursor: 'pointer', display: 'flex' }}>
                              <div key={clienteIndex}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <i className="fa-solid fa-map-pin" style={{ marginRight: '8px', color: accuracyGroup.color, display: 'inline-block', fontSize: '1rem' }}></i>
                                  <span style={{ display: 'inline-block' }}>{direccionCliente}</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <i className="fa-solid fa-user" style={{ marginRight: '8px', color: accuracyGroup.color, display: 'inline-block', fontSize: '0.8rem' }}></i>
                                  <span style={{ display: 'inline-block' }}>{puntoGroup.nombres_clientes[clienteIndex]}</span>
                                </div>
                              </div>
                              <i className={`ml-auto fas ${selectedPuntosIds.has(puntoId) ? 'fa-solid fa-circle' : 'fa-regular fa-circle'}`} style={{ fontSize: '1rem', color: selectedPuntosIds.has(puntoId) ? 'green' : 'inherit' }} />
                            </CardHeader>
                          </Card>
                        )
                      ))
                    ))}

                  </CardBody>
                </Collapse>
              </Card>
            ))}
          </CardBody>
        </Card>
      </div>
    </DndProvider>
  );  
};
    
export default GeocodingInformation;
