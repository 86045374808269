/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import LeafletMapOrders from './components/Leaflet/LeafletMapOrders';
import OrdersInformation from './components/OrdersResults/OrdersInformation';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './scss/Dashboard.scss';
import DnaLoader from "../components/Loading/DnaLoader";
import { dataCoordinates } from "../api/services/get/Coordinates";
import { useExportToExcel } from "../api/services/get/OrdersExcel";
import getDecryptedAccessToken from '../auth/AccessToken.js';
import config from '../config.js';

function Orders(props) {
  const [clientes, setClientes] = useState([]);
  const [direccionClientes, setDireccionClientes] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [itemPedidos, setItemPedidos] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [bodegas, setBodegas] = useState([]);
  const [despachos, setDespachos] = useState([]);
  const [despachoActual, setDespachoActual] = useState({});
  const [despachoIdActual, setDespachoIdActual] = useState(0);
  const accessToken = getDecryptedAccessToken();
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [selectedAccuracys, setSelectedAccuracys] = useState(new Set());
  const [selectedPuntosIds, setSelectedPuntosIds] = useState(new Set());
  const [showPuntosNuevos, setShowPuntosNuevos] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [markerInitialPositions, setMarkerInitialPositions] = useState({});
  const [markerPositions, setMarkerPositions] = useState({});
  const [numPages, setNumPages] = useState(1);
  const [actualPage, setActualPage] = useState(0);
  const prevLocation = useLocation();
  const [filtroNombre, setFiltroNombre] = useState('');
  const [nombreDespacho, setNombreDespacho] = useState('');
  const [refresh, setRefresh] = useState(false);
  const delta = 0.1; // Ajusta según la extensión deseada
  const initCoordinatesBounds = [4.662112, -74.117308];
  const initialBounds = [
    [initCoordinatesBounds[0] - delta, initCoordinatesBounds[1] - delta],
    [initCoordinatesBounds[0] + delta, initCoordinatesBounds[1] + delta]
  ];
  const [bounds, setBounds] = useState(initialBounds);
  const [responseBackend, setResponseBackend] = useState(false);
  const { handleExport } = useExportToExcel();

  const dataInputRuteo = async (despacho_id) => {
    try {
      const apiUrl = `${config.apiBaseUrl}/ConsultarPedidos/?despacho_id=${despacho_id}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(apiUrl, { headers });
  
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Error en la solicitud:', response.data);
        navigate(`/login`);
      }
    } catch (error) {
      console.error('Error en la respuesta del servidor:', error);
      navigate(`/login`);
    }
  };

  useEffect(() => {
    const despacho = despachos.find(d => d.despacho_id === despachoIdActual);
    if (despacho) {
      setDespachoActual(despacho);
      setNombreDespacho(despacho.nombre);
    }
  }, [filtroNombre,despachoIdActual]);
  
  useEffect(() => {
    const { data } = prevLocation.state || {};
    const getDataInput = async () => {
      const dataInputResponse = await dataInputRuteo(despachoIdActual);
      // console.log(dataInputResponse);
      setPedidos(dataInputResponse.pedidos);
      setClientes(dataInputResponse.clientes);
      setDireccionClientes(dataInputResponse.direccion_clientes);
      setArticulos(dataInputResponse.articulos);
      setItemPedidos(dataInputResponse.item_pedidos);
      setBodegas(dataInputResponse.bodegas);
      setDespachos(dataInputResponse.despachos);
      const despacho = dataInputResponse.despachos.find(d => d.despacho_id === dataInputResponse.despacho_actual);
      const numPedidos = dataInputResponse.pedidos.filter(d => d.despacho_id === dataInputResponse.despacho_actual).length;
      if (despacho){
        setDespachoActual(despacho);
        setNombreDespacho(despacho.nombre);
      };
      setDespachoIdActual(dataInputResponse.despacho_actual);
      if (numPedidos> 0) {
        setNumPages(Math.ceil(numPedidos / 100));
      } else {
        setNumPages(1);
      };
    };
    const getCoordinates = async () => {
      const dataResponse = await dataCoordinates();
      if (dataResponse.grupo.length > 0) {
        const delta = 0.1; // Ajusta según la extensión deseada
        const newBounds = [
          [dataResponse.grupo[0] - delta, dataResponse.grupo[1] - delta],
          [dataResponse.grupo[0] + delta, dataResponse.grupo[1] + delta]
        ];
        setBounds(newBounds);
      };
    }
    getDataInput();
    getCoordinates();
    setResponseBackend(true);
    
  }, [refresh]);
  
  return (
    <>
        <div className="content-leafletmap">
          {uploading && (
          <div className="dna-wrapper-background">
            <DnaLoader visible={true} height="50" width="50" ariaLabel="dna-loading" />
          </div>
          )}
          <LeafletMapOrders pedidos={pedidos} despachoIdActual={despachoIdActual} setSelectedAccuracys={setSelectedAccuracys} selectedAccuracys={selectedAccuracys} selectedPuntosIds={selectedPuntosIds} setSelectedPuntosIds={setSelectedPuntosIds} showButtons={showButtons} setShowButtons={setShowButtons} markerInitialPositions={markerInitialPositions} setMarkerInitialPositions={setMarkerInitialPositions} markerPositions={markerPositions} setMarkerPositions={setMarkerPositions} showPuntosNuevos={showPuntosNuevos} setShowPuntosNuevos={setShowPuntosNuevos} uploading={uploading} setUploading={setUploading} numPages={numPages} actualPage={actualPage} setActualPage={setActualPage} filtroNombre={filtroNombre} bounds={bounds} responseBackend={responseBackend}/>
        </div>
        <div className="content-left-container">
          <OrdersInformation
            despachos={despachos}
            nombreDespacho={nombreDespacho}
            setNombreDespacho={setNombreDespacho}
            despachoActual={despachoActual}
            despachoIdActual={despachoIdActual}
            setDespachoIdActual={setDespachoIdActual}
            pedidos={pedidos}
            clientes={clientes}
            direccionClientes={direccionClientes}
            articulos={articulos}
            itemPedidos={itemPedidos}
            bodegas={bodegas}
            setSelectedAccuracys={setSelectedAccuracys}
            selectedAccuracys={selectedAccuracys}
            selectedPuntosIds={selectedPuntosIds}
            setSelectedPuntosIds={setSelectedPuntosIds}
            showButtons={showButtons}
            setShowButtons={setShowButtons}
            markerInitialPositions={markerInitialPositions}
            setMarkerInitialPositions={setMarkerInitialPositions}
            markerPositions={markerPositions}
            setMarkerPositions={setMarkerPositions}
            showPuntosNuevos={showPuntosNuevos}
            setShowPuntosNuevos={setShowPuntosNuevos}
            uploading={uploading}
            setUploading={setUploading}
            numPages={numPages}
            actualPage={actualPage}
            setActualPage={setActualPage}
            filtroNombre={filtroNombre}
            setFiltroNombre={setFiltroNombre}
            handleExport={handleExport}
          />
        </div>
    </>
  );
}

export default Orders;
