import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import React, { useState }  from "react";
import Switch from 'react-switch';
import { postEditarVehiculos } from "../../../api/services/post/EditarVehiculos";
import './GeocodingRequest.scss';
import {postRuteo} from "../../../api/services/post/Ruteo";

const GeocodingRequest = ({ modalVehiclesOpened, setModalVehiclesOpened, dataVehiculos, selectedVehicles, setSelectedVehicles, navigate, setUploading, despachoId, puntosGeograficosToUpdate }) => {

    const handleSelectVehicles = (id) => setSelectedVehicles((prevSelectedVehicles) => {
        const newVehicles = new Set(prevSelectedVehicles);
        newVehicles.has(id) ? newVehicles.delete(id) : newVehicles.add(id);
        return newVehicles;
    });

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setModalVehiclesOpened(false);
        setUploading(true);
    
        const dataVehiculosSelected = dataVehiculos.map((vehiculo) => ({
            vehiculo_id: vehiculo.vehiculo_id,
            activar: selectedVehicles.has(vehiculo.vehiculo_id),
        }));
    
        try {
            await postEditarVehiculos(dataVehiculosSelected, navigate);
            await postRuteo(puntosGeograficosToUpdate, despachoId, navigate);
        } catch (error) {
            console.error('Error during submission:', error);
        } finally {
            setUploading(false);
        }
    };    

    return (modalVehiclesOpened ? (
        <Modal
            isOpen={modalVehiclesOpened}
            toggle={() => setModalVehiclesOpened(!modalVehiclesOpened)}
            modalClassName="modal-black"
            size="lg"
        >
            <ModalHeader toggle={() => setModalVehiclesOpened(!modalVehiclesOpened)}>
                <div className="modal-header justify-content-center">
                    <h1 className="modal-title">Seleccionar vehículos</h1>
                </div>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Card className="card-tasks">
                            <CardHeader>
                                <h6 className="title d-inline">Vehículos ({selectedVehicles.size}/{dataVehiculos.length})</h6>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Seleccionar</th>
                                                <th>Placa</th>
                                                <th>Horario</th>
                                                <th>Capacidad (kg)</th>
                                                <th>Largo (m)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataVehiculos.map((vehiculo) => (
                                                <tr key={vehiculo.vehiculo_id}>
                                                    <td>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Switch
                                                                    onChange={() => handleSelectVehicles(vehiculo.vehiculo_id)}
                                                                    checked={selectedVehicles.has(vehiculo.vehiculo_id)}
                                                                    offColor="#888"
                                                                    onColor="#0dcaf0"
                                                                    height={20}
                                                                    width={48}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <h4 className="title m-0 placa-highlight">{vehiculo.placa}</h4>
                                                    </td>
                                                    <td>
                                                        {vehiculo.horaInicio} - {vehiculo.horaFin}
                                                    </td>
                                                    <td>
                                                        <i className="fa-solid fa-circle-check" style={{ color: '#63E6BE', marginRight: '0.2rem' }}></i>
                                                        <i className="fa-solid fa-weight-scale" style={{ color: '#ffffff', marginRight: '0.5rem' }}></i>
                                                        {vehiculo.capacidadPeaje} kg <br />
                                                        <i className="fa-solid fa-circle-xmark" style={{ color: '#cc3f0f', marginRight: '0.2rem' }}></i>
                                                        <i className="fa-solid fa-weight-scale" style={{ color: '#ffffff', marginRight: '0.5rem' }}></i>
                                                        {vehiculo.capacidadNoPeaje} kg
                                                    </td>
                                                    <td>
                                                        {vehiculo.largo} m
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="col-12 d-flex justify-content-center" style={{ marginTop:'-1rem' }}>
                                    <Button
                                        className="btn btn-primary animation-on-hover"
                                        onClick={handleSubmitForm}
                                        color="info"
                                        size="lg"
                                    >
                                        Rutear
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    ) : null);
}

export default GeocodingRequest;
