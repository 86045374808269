import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Collapse, Card, CardBody, CardHeader, Badge, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './RouteInformation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import getDecryptedAccessToken from '../../../auth/AccessToken.js';
import config from '../../../config.js';

const DraggableItem = ({ nombre_cliente, pedido_id, tipoNodo, direccion, estimadoLlegada, pesoTotal, maxLargo,
                         color_leaflet, id_externo_pedido, itemsPedido, reordenado, index, moveItem }) => {
  
  const formattedEstimadoLlegada = estimadoLlegada
    ? moment(estimadoLlegada).format('YYYY-MM-DD HH:mm:ss')
    : 'No disponible';

  const [, drop] = useDrop({
      accept: 'ITEM',
      hover: (draggedItem) => {
        // No realizamos ninguna acción durante el hover
        // console.log(draggedItem.index);
      },
      drop: (item) => {
        // Realiza el movimiento solo si se ha almacenado la posición inicial
        // console.log(item.index, index, item.pedido_id, pedido_id);
        moveItem(item.index, index, item.pedido_id, pedido_id);
      },
    });  

    const [{ isDragging }, drag] = useDrag({
        type: 'ITEM',
        item: { index, pedido_id }, // Pasa la función directamente al objeto item
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });

      const [isCardHovered, setIsCardHovered] = useState(false);
      const [isIconHovered, setIsIconHovered] = useState(false);
      const [isMenuOpen, setIsMenuOpen] = useState(false);

      const handleCardMouseEnter = () => {
        setIsCardHovered(true);
      };
    
      const handleCardMouseLeave = () => {
        setIsCardHovered(false);
      };
    
      const handleIconMouseEnter = () => {
        setIsIconHovered(true);
        // console.log('Icon Hovered:', true);
      };
    
      const handleIconMouseLeave = () => {
        setIsIconHovered(false);
      };

      const handleIconClick = () => {
        setIsMenuOpen(!isMenuOpen);
      };
      
    if (tipoNodo === 'CLI') {
      return (
        <div
        ref={(node) => drag(drop(node))}
        style={{ cursor: 'grab', position: 'relative' }}
        onMouseEnter={handleCardMouseEnter}
        onMouseLeave={handleCardMouseLeave}
        >
          <Card
            key={pedido_id}
            className="mb-2"
            style={{
              paddingLeft: '20px',
              borderColor: reordenado ? '#f5365c' : '',
              borderWidth: reordenado ? '2px' : '', // Asegúrate de definir un ancho para que el borde sea visible
              borderStyle: reordenado ? 'solid' : '' // Define el estilo del borde para que sea sólido
            }}
          >
            {isCardHovered && (
              <div style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', paddingLeft: '10px' }}>
                <FontAwesomeIcon
                  icon={faGripVertical}
                  style={{ color: '#808080', cursor: 'pointer' }}
                  onMouseEnter={handleIconMouseEnter}
                  onMouseLeave={handleIconMouseLeave}
                  onClick={handleIconClick}
                  id={`icon-tooltip-${pedido_id}`}
                />
                {isIconHovered && (
                  <div className="custom-tooltip">
                    <div className="uppercase"><strong>Drag</strong> to move</div>
                    <div className="uppercase"><strong>Click</strong> to open menu</div>
                  </div>
                )}
              </div>
            )}
            <CardHeader style={{ cursor: 'pointer' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className="fa-solid fa-box" style={{ marginRight: '8px', color: color_leaflet}}></i>
                <span>{id_externo_pedido}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className="fa-regular fa-user" style={{ marginRight: '8px', color: color_leaflet }}></i>
                <span>{`${nombre_cliente || 'Cliente no disponible'}`}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className="fa-solid fa-map-pin" style={{ marginRight: '8px', color: color_leaflet }}></i>
                <span>{`${direccion || 'Dirección no disponible'}`}</span>
              </div>
            </CardHeader>
            <CardBody>
              <div>
                <i className="fa-solid fa-clock" style={{ marginRight: '8px', color: 'white', display: 'inline-block' }}></i>
                <h5 style={{ display: 'inline-block' }}>{formattedEstimadoLlegada}</h5>
              </div>
              <Badge color="info">{`${pesoTotal ? pesoTotal.toFixed(0) + ' kg' : '0 kg'}`}</Badge>
              <Badge color="info" className="ml-2">{`${itemsPedido ? itemsPedido + ' Items' : '0 Items'}`}</Badge>
              <Badge color="info" className="ml-2">{`${maxLargo ? maxLargo.toFixed(0) + ' m' : '0 m'}`}</Badge>
            </CardBody>
          </Card>
        </div>
      );
    }
  else {
    return (
      <Card key={pedido_id} className="mb-2">
        <CardHeader style={{ cursor: 'pointer' }}>
          {tipoNodo === 'VEH' && (
            <i className="fa-solid fa-truck" style={{ marginRight: '8px', color: color_leaflet}}></i>
          )}
          {tipoNodo === 'BOD' && (
            <i className="fa-solid fa-warehouse" style={{ marginRight: '8px', color: color_leaflet }}></i>
          )}
          {`${direccion || 'Dirección no disponible'}`}
        </CardHeader>
        <CardBody>
            <div>
              <i className="fa-solid fa-clock" style={{ marginRight: '8px', color: 'white', display: 'inline-block' }}></i>
              <h5 style={{ display: 'inline-block' }}>{formattedEstimadoLlegada}</h5>
            </div>
        </CardBody>
      </Card>
    );
  }
};

const DraggableList = ({ items, moveItem }) => {
  const filteredItems = items.filter(item => !(item.tipoNodo === 'VEH' && item.secuencia_punto === 0));

  return (
    <CardBody>
      {filteredItems.map((item, index) => (
        <DraggableItem
          key={item.pedido_id}
          index={index}
          moveItem={moveItem}
          {...item}
        />
      ))}
    </CardBody>
  );
};

const RouteInformation = ({ dataFromBackend, setDataFromBackend, selectedPlacas, setSelectedPlacas, selectedRutaIds,
                            setSelectedRutaIds, planeaciones, planeacionId, setPlaneacionId, setUploading, vehiculos,
                            handleExport, setRouteReordered }) => {
  const navigate = useNavigate();
  const accessToken = getDecryptedAccessToken();
  const numPlaneaciones = planeaciones.length;
  const actualPlaneacion = planeaciones.findIndex(p => p.planeacion_id === planeacionId);
  const getInitialActivePlaneacion = () => {
    if (actualPlaneacion >= 0) {
      return planeaciones[actualPlaneacion].activo === 1;
    }
    return false;
  };

  const [activePlaneacion, setActivePlaneacion] = useState(getInitialActivePlaneacion());
  const rutaColors = {};
  const rutaSecuence = {};
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [actualVehiclePlaca, setActualVehiclePlaca] = useState(null);
  const [selectedVehiclePlaca, setSelectedVehiclePlaca] = useState(null);
  const [selectedRutasVehiculo, setSelectedRutasVehiculo] = useState(null);

  const toggleModal = () => setModalOpen(!modalOpen);

  // console.log(dataFromBackend);

  dataFromBackend.forEach((item) => {
    rutaColors[item.ruta_id] = item.color_leaflet;
    rutaSecuence[item.ruta_id] = item.secuencia_ruta;
  });

  const placaGroups = {};
  dataFromBackend.forEach((item) => {
    if (!placaGroups[item.rutasVehiculo_id]) {
      placaGroups[item.rutasVehiculo_id] = {
        rutas: {},
        distanciaTotal: 0,
        pesoTotal: 0,
        placa: '',
      };
    }
    if (!placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id]) {
      placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id] = {
        pedidos: {},
        pedidos_clientes: [],
        distanciaTotal: 0,
        pesoTotal: 0,
        visitas: 0,
      };
    }
    if (!placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id]) {
      placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id] = {
        ids: [],
        largos: [],
        pesoTotal: 0,
        maxLargo: 0,
        secuencia_punto: '',
        estimadoLlegada: '',
        direccion: '',
        tipoNodo: '',
        color_leaflet: '',
        itemsPedido: 0,
        pedido_id: '',
        nombre_cliente: '',
        id_externo_pedido: '',
        reordenado: false,
      };
    }
    if (!placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].ids[item.id]) {
      placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].ids[item.id] = [];
    }
    const currentGroup = placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].ids[item.id];
    currentGroup.push(item);
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].ids[item.id] = currentGroup;
    // Actualizar distancia total y peso total
    placaGroups[item.rutasVehiculo_id].distanciaTotal += item.distancia;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].distanciaTotal += item.distancia;
    placaGroups[item.rutasVehiculo_id].pesoTotal += item.peso_total;
    placaGroups[item.rutasVehiculo_id].placa = item.placa;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pesoTotal += item.peso_total;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].pesoTotal += item.peso_total;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].direccion = item.direccion;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].estimadoLlegada = item.estimadoLlegada;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].secuencia_punto = item.secuencia_punto;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].tipoNodo = item.tipoNodo;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].color_leaflet = item.color_leaflet;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].itemsPedido += item.cantidad;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].pedido_id = item.pedido_id;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].nombre_cliente = item.nombre;
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].id_externo_pedido = item.id_externo_pedido;
    if (item.reordenado) {
      placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].reordenado = true;
    }
    if (item.tipoNodo === 'CLI'){
      placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos_clientes.push(item.pedido_id);
      placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].largos.push(item.largo);

    }
    const conjuntoUnicoPedidos = new Set(placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos_clientes);
    const valoresUnicosPedidos = [...conjuntoUnicoPedidos];
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].visitas = valoresUnicosPedidos.length;
    const maxLargo = Math.max(...placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].largos);
    placaGroups[item.rutasVehiculo_id].rutas[item.ruta_id].pedidos[item.pedido_id].maxLargo = maxLargo;
  });

    const handlePlacaClick = (placa) => {
    setSelectedPlacas((prevSelectedPlacas) => {
      const newSelectedPlacas = new Set(prevSelectedPlacas);
      if (newSelectedPlacas.has(placa)) {
        newSelectedPlacas.delete(placa);
        // También cierra todas las rutas de este vehículo
        Object.keys(placaGroups[placa].rutas).forEach(rutaId => {
          setSelectedRutaIds((prevSelectedRutaIds) => {
            const newSelectedRutaIds = new Set(prevSelectedRutaIds);
            newSelectedRutaIds.delete(rutaId);
            return newSelectedRutaIds;
          });
        });
      } else {
        newSelectedPlacas.add(placa);
      }
      return newSelectedPlacas;
    });
  };

  const handleRutaIdClick = (rutaId) => {
    setSelectedRutaIds((prevSelectedRutaIds) => {
      const newSelectedRutaIds = new Set(prevSelectedRutaIds);
      if (newSelectedRutaIds.has(rutaId)) {
        newSelectedRutaIds.delete(rutaId);
      } else {
        newSelectedRutaIds.add(rutaId);
      }
      return newSelectedRutaIds;
    });
  };

  const handleMoveItem = (fromIndex, toIndex, fromId, toId) => {
    if (fromId !== toId) {
      // Supongamos que dataFromBackend es tu array
      const newDataFromBackend = dataFromBackend.map(item => ({ ...item })); // Clonar los objetos dentro del array
      // Encuentra todos los índices correspondientes a fromId en newDataFromBackend
      const fromIndexPositions = newDataFromBackend.reduce((indices, item, index) => {
        if (item.pedido_id === fromId) {
          indices.push(index);
        }
        return indices;
      }, []);
  
      // Elimina elementos de fromIndexPositions de newDataFromBackend
      const removedData = newDataFromBackend.filter((item, index) => fromIndexPositions.includes(index));
  
      let newData = newDataFromBackend.filter((item, index) => !fromIndexPositions.includes(index));
      
      // Encuentra todos los índices correspondientes a toId en newDataFromBackend
      const toIndexPositions = newData.reduce((indices, item, index) => {
        if (item.pedido_id === toId) {
          indices.push(index);
        }
        return indices;
      }, []);
  
      // Obtener el elemento a mover y los valores de toIndex
      const itemTarget = newDataFromBackend[toIndexPositions[0]];
  
      const fromRutaId = removedData[0].ruta_id;
  
      // Asignar los valores de itemTarget a cada elemento en itemsToMove
      removedData.forEach((item) => {
        item.ruta_id = itemTarget.ruta_id;
        item.rutasVehiculo_id = itemTarget.rutasVehiculo_id;
        item.color_leaflet = itemTarget.color_leaflet;
        item.reordenado = true;
        // Asignar otros valores si es necesario
      });
  
      // let toIndexPosition = Math.max(...toIndexPositions);
      let toIndexPosition = toIndexPositions[0];
      if (toIndexPositions.length > 0) {
        if (fromIndex < toIndex){
          toIndexPosition = toIndexPositions[toIndexPositions.length - 1] + 1;
        }
      }
  
      // Insertar removedData en newData a partir de toIndexPosition
      newData.splice(toIndexPosition, 0, ...removedData);
  
      // Actualizar la columna Secuencia
      let currentRutaId = newData[0].ruta_id;
      let currentPuntoId = newData[0].nodo_id;
      let secuencia = 1;
      let secuencia_punto = 0;
  
      newData.forEach((item, index) => {
        if (index > 0 && item.ruta_id !== currentRutaId) {
          currentRutaId = item.ruta_id;
          currentPuntoId = item.nodo_id;
          secuencia = 1;
          secuencia_punto = 0;
        }
        if (index > 0 && item.nodo_id !== currentPuntoId) {
          currentPuntoId = item.nodo_id;
          secuencia_punto++;
        }
        if (item.ruta_id === fromRutaId || item.ruta_id === itemTarget.ruta_id) {
          item.ruta = "[]";
          item.estimadoLlegada = "";
          item.distancia = 0;
        }
        item.secuencia_punto = secuencia_punto;
        item.secuencia = secuencia;
        secuencia++;
      });
      setDataFromBackend(newData);
      setRouteReordered(true);
    }
  };

  const sortBySecuencia = (items) => {
    return Object.values(items).sort((a, b) => a.secuencia_punto - b.secuencia_punto);
  };

  const goToFirstPlaneacion = () => {
    setPlaneacionId(planeaciones[0].planeacion_id);
    setSelectedPlacas(new Set());
    setSelectedRutaIds(new Set());
  };

  const goToPrevPlaneacion = () => {
    if (actualPlaneacion > 0) {
      setPlaneacionId(planeaciones[actualPlaneacion - 1].planeacion_id);
      setSelectedPlacas(new Set());
      setSelectedRutaIds(new Set());
    }
  };

  const goToNextPlaneacion = () => {
    if (actualPlaneacion < numPlaneaciones - 1) {
      setPlaneacionId(planeaciones[actualPlaneacion + 1].planeacion_id);
      setSelectedPlacas(new Set());
      setSelectedRutaIds(new Set());
    }
  };

  const goToLastPlaneacion = () => {
    setPlaneacionId(planeaciones[numPlaneaciones - 1].planeacion_id);
    setSelectedPlacas(new Set());
    setSelectedRutaIds(new Set());
  };

  const handleConfirmEdit = () => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/EditarRutasVehiculo/`;
    const postData = {
      'vehiculo_id':selectedVehicle,
      'rutasVehiculo_id':selectedRutasVehiculo,
      'planeacion_id':planeacionId
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  const handleVehicleMenuClick = (vehiculo, rutasVehiculo_id, actualPlaca) => {

    setSelectedVehicle(vehiculo.vehiculo_id);
    setSelectedRutasVehiculo(rutasVehiculo_id);
    setActualVehiclePlaca(actualPlaca);
    setSelectedVehiclePlaca(vehiculo.placa);

    toggleModal();
  };

  useEffect(() => {
    setActivePlaneacion(planeaciones[actualPlaneacion]?.activo === 1);
  }, [planeacionId, actualPlaneacion, planeaciones]);

  const handleActivarPlaneacion = () => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/ActivarPlaneacion/`;
    const postData = {
      'planeacion_id':planeacionId
    };
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  const isNumeric = (value) => {
    return !isNaN(value) && isFinite(value) && value>0;
  };
  
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div className="title-container">
          <h2 className="text-center mb-3 text-white" style={{ paddingTop: '1rem', paddingBottom: '5px' }}>RUTAS VEHÍCULOS</h2>
          <div className="divider-line"></div>
        </div>
        <Modal isOpen={modalOpen} toggle={toggleModal} modalClassName="modal-black-original">
          <ModalHeader toggle={toggleModal}>Confirmar Acción</ModalHeader>
          <ModalBody>
            {actualVehiclePlaca && selectedVehiclePlaca ? (
              <p>
                ¿Está seguro que desea asignar las ruta/s del vehículo{' '}
                <span style={{ backgroundColor: '#ffc107', padding: '0.2rem', borderRadius: '4px', color: '#000', fontWeight: 'bold' }}>
                  {actualVehiclePlaca}
                </span>{' '}
                al vehículo{' '}
                <span style={{ backgroundColor: '#ffc107', padding: '0.2rem', borderRadius: '4px', color: '#000', fontWeight: 'bold' }}>
                  {selectedVehiclePlaca}
                </span>?
              </p>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            <Button color="primary" onClick={handleConfirmEdit}>Confirmar</Button>
          </ModalFooter>
        </Modal>
        {(planeacionId > 0) && (
          <Card className="mb-3" style={{ marginTop: '10px' }}>
            <CardHeader>
              <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                <div>
                  <FontAwesomeIcon icon={faAngleDoubleLeft} onClick={goToFirstPlaneacion} style={{ cursor: 'pointer' }} />
                  <FontAwesomeIcon icon={faAngleLeft} onClick={goToPrevPlaneacion} style={{ cursor: 'pointer' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Ruteo {actualPlaneacion + 1} de {numPlaneaciones}</span>
                  {activePlaneacion && <FontAwesomeIcon icon={faCheckCircle} style={{ marginLeft: '5px', color: 'green' }} />}
                </div>
                <div>
                  <FontAwesomeIcon icon={faAngleRight} onClick={goToNextPlaneacion} style={{ cursor: 'pointer' }} />
                  <FontAwesomeIcon icon={faAngleDoubleRight} onClick={goToLastPlaneacion} style={{ cursor: 'pointer' }} />
                </div>
              </div>
              {/* <div style={{ marginTop: '10px' }}>
                {activePlaneacion ? (
                  <Button color="success" disabled style={{ width: '100%' }}>Activar</Button>
                ) : (
                  <Button color="success" style={{ width: '100%' }} onClick={handleActivarPlaneacion}>Activar</Button>
                )}
              </div> */}
              <div className="form-row d-flex align-items-center" style={{ marginTop: '10px' }}>
                <div className="col-md-10">
                  {activePlaneacion ? (
                    <Button color="success" disabled style={{ width: '100%' }}>Activar</Button>
                  ) : (
                    <Button color="success" style={{ width: '100%' }} onClick={handleActivarPlaneacion}>Activar</Button>
                  )}
                </div>
                <div className="col-md-2">
                  <Button className="btn-icon w-100" color="warning" onClick={() => handleExport(planeacionId)}>
                    <i className="fa-solid fa-download"></i>
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {Object.entries(placaGroups).map(([placa, placaGroup]) => (
                <Card key={placa} className="mb-3" style={{ marginTop: '10px' }}>
                  <CardHeader style={{ display: 'flex', alignItems: 'center', paddingBottom: '1rem', paddingTop: '1rem' }}>
                    <UncontrolledDropdown style={{ marginRight: '0.5rem' }}>
                      <DropdownToggle caret color="warning" data-toggle="dropdown" style={{ width: 'auto', padding: '0.3rem 0.5rem' }}>
                        {placaGroup.placa}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.keys(vehiculos).filter(key => vehiculos[key].placa !== placaGroup.placa).map(key => (
                          <DropdownItem key={key} onClick={() => handleVehicleMenuClick(vehiculos[key], placa, placaGroup.placa)}>
                            {vehiculos[key].placa}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Badge color="info" className="mr-1" style={{ whiteSpace: 'nowrap' }}>
                      {Object.keys(placaGroup.rutas).length} Rutas
                    </Badge>
                    <Badge color="info" className="mr-1" style={{ whiteSpace: 'nowrap' }}>
                      {isNumeric(placaGroup.distanciaTotal) 
                        ? `${placaGroup.distanciaTotal.toFixed(0)} km` 
                        : ''}
                    </Badge>
                    <Badge color="info" className="mr-1" style={{ whiteSpace: 'nowrap' }}>
                      {placaGroup.pesoTotal ? `${placaGroup.pesoTotal.toFixed(0)} kg` : ''}
                    </Badge>
                    <div 
                      className="ml-auto" 
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} 
                      onClick={() => handlePlacaClick(placa)}
                    >
                      <i className={`fas ${selectedPlacas.has(placa) ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ fontSize: '1rem' }} />
                    </div>
                  </CardHeader>
                  <Collapse isOpen={selectedPlacas.has(placa)}>
                    <CardBody style={{ paddingBottom: '1rem', paddingTop: '0.5rem' }}>
                      {Object.entries(placaGroup.rutas).map(([rutaId, rutaGroup]) => (
                        <Card key={rutaId} className="mb-2">
                          <CardHeader onClick={() => handleRutaIdClick(rutaId)} style={{ cursor: 'pointer', display: 'flex' }}>
                            <span style={{ color: rutaColors[rutaId] }}>{`Ruta ${rutaSecuence[rutaId]}`}</span>
                            <Badge color="info" className="ml-2">
                              {rutaGroup.visitas} Pedidos
                            </Badge>
                            <Badge color="info" className="ml-2">
                              {isNumeric(rutaGroup.distanciaTotal) 
                                ? `${rutaGroup.distanciaTotal.toFixed(0)} km` 
                                : ''}
                            </Badge>
                            <Badge color="info" className="ml-2">
                              {rutaGroup.pesoTotal ? `${rutaGroup.pesoTotal.toFixed(0)} kg` : ''}
                            </Badge>
                            <i className={`ml-auto fas ${selectedRutaIds.has(rutaId) ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ fontSize: '1rem' }} />
                          </CardHeader>
                          <Collapse isOpen={selectedRutaIds.has(rutaId)}>
                            <DraggableList items={sortBySecuencia(rutaGroup.pedidos)} moveItem={(fromIndex, toIndex, fromId, toId) => handleMoveItem(fromIndex, toIndex, fromId, toId)} />
                          </Collapse>
                        </Card>
                      ))}
                    </CardBody>
                  </Collapse>
                </Card>
              ))}
            </CardBody>
          </Card>
        )}
      </div>
    </DndProvider>
  );  
};
    
export default RouteInformation;
