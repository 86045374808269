import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon
} from 'mdb-react-ui-kit';
import { Button } from "reactstrap";
// import { useAuth } from './AuthContext.js';
import config from '../config.js';
import CryptoJS from 'crypto-js';
import libotIcon from '../assets/img/libot_icon.png'
import DnaLoader from "../components/Loading/DnaLoader";

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const apiUrl = `${config.apiBaseUrl}/login/`;
  const [uploading, setUploading] = useState(false);
  // const { setLoggedIn } = useAuth();
  const nav = useNavigate();
  const handleLogin = async () => {
    setUploading(true);
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        const { access } = data;

        // Obtén la clave de cifrado y el iv desde la configuración
        const key = CryptoJS.enc.Hex.parse(config.secretKey);
        const iv = CryptoJS.enc.Hex.parse(config.initializationVector);

        // Cifra el token de acceso
        const encAccess = CryptoJS.AES.encrypt(access, key, {
          iv: iv,
        });
  
        // Almacena el token cifrado en el almacenamiento local
        localStorage.setItem('accessToken', encAccess.toString());
  
        // Establece el estado `loggedIn` en `true`
        // setLoggedIn(true);
        // Redirige a dashboard
        nav("/admin/start");
      } else {
        // Maneja el error de inicio de sesión aquí
        console.error('Inicio de sesión fallido');
        setUploading(false);
        setError('Usuario o contraseña incorrectos');
      }
    } catch (error) {
      // Maneja errores de red
      console.error('Error de red:', error);
      setUploading(false);
      setError('Usuario o contraseña incorrectos');
    }
  };
  
  

  return (
    <MDBContainer fluid className='d-flex justify-content-center align-items-center h-100'>
      {uploading && (
        <div className="dna-wrapper-background">
          <DnaLoader visible={true} height="50" width="50" ariaLabel="dna-loading" />
        </div>
      )}
      <MDBRow className='justify-content-center'>
        <MDBCol col='12'>

          <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
              <img src={libotIcon} alt="Libot Icon" style={{ marginBottom: '20px', width: '100px', height: '100px' }} />
              
              <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
              <p className="text-white-50 mb-5">Please enter your login and password!</p>

              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Username' id='formControlLg' type='username' size="lg" value={username} onChange={(e) => setUsername(e.target.value)}/>
              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Password' id='formControlLg' type='password' size="lg" value={password} onChange={(e) => setPassword(e.target.value)}/>
              {error && (
                <h5 className="fw-bold mb-2" style={{ color:"#fd5d93" }}>{error}</h5>
              )}
              <p className="small mb-3 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>
              {/* <MDBBtn outline className='mx-2 px-5' color='white' size='lg' onClick={handleLogin}>
                Login
              </MDBBtn> */}
              <Button color="info" className="animation-on-hover" size='lg' onClick={handleLogin}>
                Login
              </Button>

              <div className='d-flex flex-row mt-3 mb-5'>
                <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                  <MDBIcon fab icon='facebook-f' size="lg"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                  <MDBIcon fab icon='twitter' size="lg"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                  <MDBIcon fab icon='google' size="lg"/>
                </MDBBtn>
              </div>

              <div>
                <p className="mb-0">Don't have an account? <a href="#!" class="text-white-50 fw-bold">Sign Up</a></p>

              </div>
            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>

  </MDBContainer>
  );
}

export default Login;
