import axios from "axios";
import getDecryptedAccessToken from "../../../auth/AccessToken";
import config from "../../../config";

export   const dataCoordinates = async () => {
    const accessToken = getDecryptedAccessToken();
    // const navigate = useNavigate();

    try {
      const apiUrl = `${config.apiBaseUrl}/ConsultarCoordenadas/`;
    
      // Verifica si se ha almacenado un token de acceso en localStorage
      if (!accessToken) {
        console.error('No se encontró un token de acceso en localStorage');
        return;
      }
    
      // Configura los encabezados para incluir el token de acceso como Bearer
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.get(apiUrl, { headers });
    
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Error en la solicitud:', response.data);
        // navigate(`/login`);
      }
    } catch (error) {
      console.error('Error en la respuesta del servidor:', error);
      // navigate(`/login`);
    }
  };  