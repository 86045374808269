import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import pejae_icon from '../../../assets/img/peaje.png'
import './map.css';
// import markerIcon from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as MarkerIcon} from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as ArrowsIcon} from '../../../assets/img/arrows-out-cardinal-bold-svgrepo-com.svg';
import {Button} from 'reactstrap';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import axios from 'axios';
import getDecryptedAccessToken from '../../../auth/AccessToken.js';

function createCustomIcon(color, markerSelected) {
  const CustomIcon = () => (
    <div style={{ position: 'relative', width: '45px', height: '51px' }}>
      {/* {markerSelected && (
        <div style={{ position: 'absolute', top: '34%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }}>
          <ArrowsIcon fill={color} stroke='black' height='75' width='75' />
        </div>
      )} */}
      <MarkerIcon fill={color} stroke='black' height='45' width='45' style={{ position: 'relative', zIndex: '2' }} />
    </div>
  );

  return L.divIcon({
    iconSize: [50, 56],
    iconAnchor: [20, 38],
    popupAnchor: [3, -30],
    html: ReactDOMServer.renderToString(<CustomIcon />),
    className: 'dummy'
  });
}

function Map({ coordinates, isValidCoordinates }) {
  const map = useMap();

  if (isValidCoordinates && coordinates.length >= 2) {
    map.fitBounds(coordinates);
  } else if (isValidCoordinates && coordinates.length > 0) {
    // Si hay menos de dos coordenadas, ajusta el zoom para que el único marcador sea visible
    const [lat, lng] = coordinates[0];
    map.setView([lat, lng], 16);
  }

  return null;
}

function LeafletMapOrders({ despachoIdActual, pedidos, setSelectedAccuracys, selectedAccuracys, setSelectedPuntosIds, selectedPuntosIds, showButtons, setShowButtons, markerInitialPositions, setMarkerInitialPositions, markerPositions, setMarkerPositions, showPuntosNuevos, setShowPuntosNuevos, uploading, setUploading, numPages, actualPage, setActualPage, filtroNombre, bounds, responseBackend }) {
  const [puntosGeograficosToUpdate, setPuntosGeograficosToUpdate] = useState([]);
  const markerRef = useRef();
  const navigate = useNavigate();
  const accessToken = getDecryptedAccessToken();
  let filteredData = selectedPuntosIds.size > 0
    ? pedidos.filter(item => selectedPuntosIds.has(item.pedido_id))
    : pedidos;

  filteredData = showPuntosNuevos
    ? filteredData.filter(item => item.pedido_pendiente)
    : filteredData;

  filteredData = despachoIdActual
    ? filteredData.filter(item => item.despacho_id === despachoIdActual)
    : filteredData;

  if (filtroNombre) {
    filteredData = filteredData.filter(item =>
      item.nombre.toLowerCase().includes(filtroNombre.toLowerCase()) ||
      item.direccion.toLowerCase().includes(filtroNombre.toLowerCase()) ||
      item.pedido_id_externo.toLowerCase().includes(filtroNombre.toLowerCase())
    );
  } else {
    filteredData = filteredData.slice(
      actualPage * 100,
      (actualPage + 1) * 100
    );
  };

  filteredData.forEach((item) => {
    item.color = '#5e72e4';
  });
  
  // Obtener todas las coordenadas de filteredData
  const initCoordinates = filteredData.map(item => {
    if (markerPositions[item.pedido_id]) {
      return markerPositions[item.pedido_id];
    } else {
      return [item.latitud, item.longitud];
    }
  });

  var coordinates = bounds;

  if (initCoordinates.length > 0) {
    coordinates = initCoordinates;
  };

  // Verificar si hay valores NaN o undefined en las coordenadas
  const isValidCoordinates = coordinates.every(coord => coord.every(val => typeof val === 'number' && !isNaN(val)));

  // Estilo CSS para el contenedor del mapa
  const mapContainerStyle = {
    width: '100%', // Ancho deseado en píxeles
    height: 'calc(100vh - 78.3px)', // Altura deseada en píxeles
    borderRadius: '5px',
  };
  
  const handleMarkerDragEnd = (pedidoId) => {
    const marker = markerRef.current;
    if (marker != null) {
      const { lat, lng } = marker.getLatLng();
      // Actualiza la posición del marcador correspondiente en markerPositions
      setMarkerPositions(prevMarkerPositions => ({
        ...prevMarkerPositions,
        [pedidoId]: [lat, lng]
      }));
    }
    setShowButtons(true); // Muestra los botones al finalizar el arrastre
  };  

  const handleConfirmLocation = (pedidoId) => {
    // Lógica para confirmar la ubicación
    setShowButtons(false);
    setSelectedPuntosIds(new Set());
    // Obtener una copia del estado actual de puntosGeograficosToUpdate
    const nuevosPuntos = { ...puntosGeograficosToUpdate };

    // Agregar o actualizar el punto en el objeto puntosGeograficosToUpdate
    nuevosPuntos[pedidoId] = {
      pedido_id: pedidoId,
      latitud: markerPositions[pedidoId][0],
      longitud: markerPositions[pedidoId][1]
    };

    // Actualizar el estado puntosGeograficosToUpdate con los nuevos puntos
    setPuntosGeograficosToUpdate(nuevosPuntos);
  };

  const handleCancelLocation = (pedidoId) => {
    // Lógica para cancelar la ubicación
    setShowButtons(false);
    const newSelectedPuntosIds = new Set();
    setSelectedPuntosIds(newSelectedPuntosIds);
    setMarkerPositions(prevMarkerPositions => ({
      ...prevMarkerPositions,
      [pedidoId]: markerInitialPositions[pedidoId]
    }));
    const nuevosPuntos = { ...puntosGeograficosToUpdate };

    // Verificar si el punto existe antes de eliminarlo
    if (nuevosPuntos.hasOwnProperty(pedidoId)) {
      // Eliminar el punto del objeto nuevosPuntos
      delete nuevosPuntos[pedidoId];
    }
    
    // Actualizar el estado puntosGeograficosToUpdate con los nuevos puntos
    setPuntosGeograficosToUpdate(nuevosPuntos);
  };

  const handlePuntoIdClick = (puntoId) => {
    let selectedPuntosAux = new Set();
    const filteredItems = filteredData.filter(item => item.punto_id === puntoId);
    const pedidosId = filteredItems.map(item => item.pedido_id);
    setShowButtons(false);
    // Variable para almacenar el resultado de la validación
    let todosPresentes = true;

    // Iterar sobre cada elemento de pedidosId y verificar si está presente en selectedPuntosIds
    pedidosId.forEach(pedidoId => {
        if (!selectedPuntosIds.has(pedidoId)) {
            todosPresentes = false;
            return; // Si un elemento no está presente, no es necesario seguir iterando
        }
    });
    if (todosPresentes) {
      setSelectedPuntosIds(selectedPuntosAux);
    } else {
      pedidosId.forEach(pedidoId => {
        selectedPuntosAux.add(pedidoId);
      });
      setSelectedPuntosIds(selectedPuntosAux);
    } 
  };

  const consultarEstadoRuta = (task_id) => {
    const apiUrl = `${config.apiBaseUrl}/ConsultarEstadoRutear/?task_id=${task_id}`;  // Agrega el ID de la tarea como parámetro de consulta en la URL
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return Promise.reject('No se encontró un token de acceso en localStorage');
    }
  
    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    // Realiza la solicitud Axios con los encabezados configurados
    return axios.get(apiUrl, { headers })
      .then(response => {
        if (response.status === 200) {
          return response.data.status;
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
          throw new Error(response.data.message || 'Error en la respuesta del servidor');
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
        throw error; // Propaga el error para que el cliente pueda manejarlo
      });
  }  

  const handleContinue = () => {
    navigate('/admin/geocoding', { state: { despachoIdActual: despachoIdActual } });
  };

  const handleContinue2 = () => {
    navigate('/admin/dashboard', { state: { despachoIdActual: despachoIdActual } });
  };

  const handleConfirmEditPoints = () => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/ActualizarPuntosGeograficos/`;
    const postData = {
      puntosGeograficosToUpdate: puntosGeograficosToUpdate,
    };

    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }

    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  const handleRestore = () => {
    setMarkerPositions(markerInitialPositions);
    setSelectedPuntosIds(new Set());
    setPuntosGeograficosToUpdate({});
  };

  useEffect(() => {
    // Al montar el componente, almacenar las posiciones iniciales de los marcadores
    const initialMarkerPositions = {};
    Object.entries(filteredData).forEach(([key, item]) => {
      initialMarkerPositions[item.pedido_id] = [item.latitud, item.longitud];
      if (puntosGeograficosToUpdate[item.pedido_id]) {
      }
    });
    setMarkerPositions(initialMarkerPositions);
    setMarkerInitialPositions(initialMarkerPositions);
  }, []); // Solo se ejecuta una vez al montar el componente

  return (
    <div>
      <MapContainer
        bounds={bounds}
        zoom={12}
        scrollWheelZoom={true}
        style={mapContainerStyle}
      >
        <Map coordinates={coordinates} isValidCoordinates={isValidCoordinates} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
        />
        {filteredData.map(item => (
            <div key={item.pedido_id}> {/* Key debe estar en el contenedor del Marker y los botones */}
              <Marker
                position={markerPositions[item.pedido_id] || [item.latitud, item.longitud]}
                icon={createCustomIcon(item.color, selectedPuntosIds.has(item.pedido_id))}
                draggable={false} // Hace que el marcador sea draggable
                ref={markerRef} // Asignar referencia al objeto markerRefs
                eventHandlers={{
                  click: () => handlePuntoIdClick(item.punto_id),
                  dragend: () => handleMarkerDragEnd(item.pedido_id),
                  // dragstart: () => handleMarkerDragStart(pedidoId)
                }}
              />
              {showButtons && (
                <div className="map-buttons-container">
                  <Button className="btn-icon" color="success" onClick={() => handleConfirmLocation(item.pedido_id)}>
                    <i className="tim-icons icon-check-2"/>
                  </Button>
                  <Button className="btn-icon" color="danger" onClick={() => handleCancelLocation(item.pedido_id)}>
                    <i className="tim-icons icon-simple-remove"/>
                  </Button>
                </div>
              )}
            </div>
        ))}
        {!showButtons && (
          <>
            {pedidos.length > 0 && (
              <>
                <div className="continue-button-container2">
                  <Button
                    color="info"
                    onClick={() => handleContinue2()}
                    style={{ fontSize: "1.6rem" }} // Estilos directos en línea
                    className="animation-on-hover"
                  >
                  <i class="fa-solid fa-eye"></i>
                  </Button>
                </div>
                <div className="continue-button-container">
                  <Button
                    color="info"
                    onClick={() => handleContinue()}
                    style={{ fontSize: "2rem" }} // Estilos directos en línea
                    className="animation-on-hover"
                  >
                    <i class="fa-solid fa-arrow-right"></i>
                  </Button>
                </div>
              </>
            )}
            {Object.keys(puntosGeograficosToUpdate).length > 0 && ( // Verificar tamaño del objeto
              <div className="restore-confirm-buttons-container">
                <Button color="success" onClick={() => handleConfirmEditPoints()}>Confirmar Cambios</Button>
                <Button color="warning" onClick={() => handleRestore()}>Restablecer Cambios</Button>
              </div> 
            )}
          </>
        )}
      </MapContainer>
    </div>
  );
}

export default LeafletMapOrders;