import axios from "axios";
import getDecryptedAccessToken from "../../../auth/AccessToken";
import config from "../../../config";

const accessToken = getDecryptedAccessToken();

export const getVehiculos = async (navigate) => {
    const apiUrl = `${config.apiBaseUrl}/ConsultarVehiculos/`;  // Agrega el ID de la tarea como parámetro de consulta en la URL
    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
        console.error('No se encontró un token de acceso en localStorage');
        return Promise.reject('No se encontró un token de acceso en localStorage');
    }

    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    // Realiza la solicitud Axios con los encabezados configurados
    return axios.get(apiUrl, { headers })
        .then(response => {
            if (response.status === 200) {
                return response.data.vehiculos;
            } else if (response.status === 401) {
                console.error('Token de acceso inválido o expirado');
                navigate(`/login`);
                throw new Error('Token de acceso inválido o expirado');
            } else {
                console.error('Error en la respuesta del servidor:', response.data.message);
                throw new Error(response.data.message || 'Error en la respuesta del servidor');
            }
        })
        .catch(error => {
            navigate(`/login`);
            console.error('Error al realizar la solicitud:', error);
            throw error; // Propaga el error para que el cliente pueda manejarlo
        });
}